*{
    --btn_font_color: #000000;
    --btn_background_color: #ffffff;
}
.main{
    padding: 0px;
    min-height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;/*neu*/

   margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}
/*Checkbox */
label {
    display: flex;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border: 2px solid #f61;
}
.checkbox_input{
    position: absolute;
    transform: scale(0);
}
input:checked ~ .checkbox{
    transform: rotate(40deg);
    border: 2px solid #f61;
    width: 20px;
    margin-left: 12px;
    border-bottom-color: #f61;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 0;
    translate: -6px;

    //background-color: #FF6B00;
    margin-bottom: 4px;

}
input:checked ~ label{
    margin-right: 16px;
}
.checkbox{
    //display: flex;
    //width: inherit;
    //height: inherit;
    //border: 2px solid #f61;
    //border-radius: 6px;
    transition: all .375s;
}
/*Checkbox */
.titel_h1{
    color: #FFFFFF;
    font-family: TradeGothicBdCn, sans-serif;
    //font-family: "TradeGothicNextLTW05-BoldCn2";
    font-weight: bold;
    font-size: 50px;
    //font-size:6vw;
    letter-spacing: 0px;
    opacity: 1;
}
.untertitel_h2{
    color: #FF6B00;
    font-family: TradeGothicBdCn, sans-serif;
    //font-family: "TradeGothicNextLTW05-BoldCn2";
    font-size: 45px;
    letter-spacing: 0px;
    opacity: 1;
}
.zwischenueberschrift_h3{
    color: #FF6B00;
    font-family: TradeGothicBdCn, sans-serif;
    //font-family: "TradeGothicNextLTW05-BoldCn2";
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0px;
    opacity: 1;
}
.teaser_h4{
    color: #666666;
    font-family: TradeGothicBdCn, sans-serif;
    //font-family: "TradeGothicNextLTW05-BoldCn2";
    font-size: 26px;
    letter-spacing: 0px;
    opacity: 1;
}
.teaser_h4_bold{
    color: #666666;
    font-family: TradeGothicBdCn, sans-serif;
    //font-family: "TradeGothicNextLTW05-BoldCn2";
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0px;
    opacity: 1;
}
.fliesstext_p{
    color: #000000;
    font-family: TradeGothic, sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-weight: normal;
    font-size: 21px;
    opacity: 1;
}
.fliesstext_p_bold{
    color: #000000;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-weight: bold;
    font-size: 21px;
    opacity: 1;
}
.ueberschrift_btn{
    color: var(--btn_font_color);
    letter-spacing: 0px;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-size: 17px;
    text-align: center;
    opacity: 1;
    background-color: var(--btn_background_color);
    padding: 15px;
    font-weight: bold;
    display: block;
    //margin: 0px auto;
    border: 0px solid #EEEEEE;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    height: 45px;
    padding: 10px 10px;
    cursor: pointer;
    display: block;
    margin: 0px auto;
    border: 0;
    outline: none;
    font-weight: bold;
}
.tinytext_p{

}
.text_input{
    width: 100%;
    height: 40px;
    padding: 10px 5px;
    margin: 0px 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #999;
    outline: none;
    background: transparent;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-size: 17px;
}
.my_span{
    color: #f61;
    font-size: 17px;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    position: relative;
    text-decoration: none;
    cursor: pointer;
}

.my_span:hover {
    color: #f60;
}

.my_span::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f60;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.my_span:hover::before {
    transform: scaleX(1);
}

.error_span{
    color: #BD451C;
    font-size: 14px;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    position: relative;
    text-decoration: none;
    margin: 0px auto;
}

.checkbox_text_p{
    color: #666;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-weight: normal;
    font-size: 17px;
    opacity: 1;
}

.quadrat_btn_beschriftung{
    //font: normal normal normal 17px/100% TradeGothicNextLTW05-Rg2;
    font: normal normal normal 17px/100% TradeGothic,sans-serif;
    letter-spacing: 0px;
    color: #666666;
    text-transform: uppercase;
    opacity: 1;
    text-align: center;
}
/*Progress bar*/
.tootip_content_p{
    color: #FFFFFF;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-weight: normal;
    font-size: 14px;
    opacity: 1;
}
.tootip_content_p_gray{
    color: #a0a0a0;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-weight: normal;
    font-size: 14px;
    opacity: 1;
}


@font-face {
  font-family: 'TradeGothicNextLTW05-Bold2';
  src: local('MyFont'), url(fonts/TradeGothicNextLTW05-Bold.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */

}
@font-face {
  font-family: 'TradeGothicNextLTW05-BoldCn2';
  src: local('MyFont'), url(fonts/TradeGothicNextLTW05-BoldCn.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'TradeGothic';
  src: local('MyFont'), url(fonts/TradeGothicNextLTW05-Rg.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
/*Progress bar*/
@media all and (max-width: 1200px){
    .fliesstext_p{
        font-size: 17px;
    }
}
@media all and (max-width: 992px){
    .teaser_h4{
            font-size: 24px;
        }
    .fliesstext_p{
        font-size: 17px;
    }
}
@media all and (max-width: 768px){

    .fliesstext_p{
        font-size: 17px;
    }
}
@media all and (max-width: 600px){
    .titel_h1 {
        font-size: 45px;
    }
    .untertitel_h2{
        font-size: 40px;
    }
    .zwischenueberschrift_h3{
        font-size: 32px;
    }
    .teaser_h4{
        font-size: 24px;
    }
    .teaser_h4_bold{
        font-size: 26px;
    }
    .fliesstext_p{
        font-size: 17px;
    }
    .fliesstext_p_bold{
        font-size: 17px;
    }
    .ueberschrift_btn{
    }
    .tinytext_p{

    }
    .text_input{
    }
    .my_span{
    }
    .error_span{
    }
    .checkbox_text_p{
        font-size: 14px;
    }
    .quadrat_btn_beschriftung{
    }
    .tootip_content_p{
    }
    .tootip_content_p_gray{
    }
}
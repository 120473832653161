.Liability_conditions_screen{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.Liability_conditions_content{
    margin-top: 100px;
    width: 75%;
    justify-content: center;

    /*
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(../../static/pexels_bg_image.jpeg);

     */


    background-position: center;
    background-size: cover;
    position: relative;
}
.Liability_conditions_content ul{
    padding-left: 25px;
}
.Liability_conditions_content ol{
    padding-left: 34px;
}
.Liability_conditions_content p{
    color: #000000;
    font-family: TradeGothic, sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-weight: normal;
    font-size: 21px;
    opacity: 1;
}
.editorical_notes{
    padding: 5px;
    min-height: 100vh;
    width: 100%;
    /*
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(../../static/pexels_bg_image.jpeg);

     */


    background-position: center;
    background-size: cover;
    position: relative;
}
.menu_container{
    text-align: center;
    margin-top: 0%;
}
.menu_btn{
    border: 1px solid  #169c7c;
    background: #1bc49b;
    padding: 20px 40px;
    font-size: 40px;
    font-family: "sans-serif";
    cursor: pointer;
    margin: 10px;
}
/*
h1, .text-h1 {
    font-size: 3.2rem;
    line-height: 3.6rem;
    letter-spacing: .025em;
}
h1, .text-h1, h2, .text-h2, h3, .text-h3, h4, .text-h4, h5, h6 {
    color: #1b9f87;
    font-weight: 200;
}
.fhg-grid-item {
    display: inline-block;
    vertical-align: top;
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
b, strong {
    font-weight: 400;
}
b, strong {
    font-weight: bold;
}
user agent stylesheet
b {
    font-weight: bold;
}
.fhg-content p {
    line-height: 2.2rem;
}
p, .text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

 */
#package_station_img{
    width: 60%;
}

@media all and (max-width: 480px) {
    #package_station_img{
        width: 100%;
    }
}
#location_icon{
    width: 10%;
    text-align: center;
}

@media all and (max-width: 40px) {
    #location_icon{
        width: 100%;
    }
}
.img_centering{
    text-align: center;
}
@media all and (max-width: 600px) {
    /*.main h1 {
        font-size: 20px;
    }
    .main a {
        font-size: 12px;
    }
    .main h2 {
        font-size: 20px;
    }*/
}

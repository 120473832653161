.add_post_screen{
    width: 100%;
    background: url(../../static/market/posts_section_background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    flex: 1;
    display: flex;
    justify-content: center;
}
.add_post_content{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding-top: 100px;
    padding-bottom: 100px;
}
.add_post_content_item{
    width: 100%;
}
.add_post_content_item_header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.add_post_content_item_header h2{
    text-align: center;
}
.download_img_field{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.download_img_field_scope{
    width: 100%;
    margin: 25px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 150px;
    max-height: 150px;
}
.download_img_field_scope_item{
    min-height: 100%;
    max-height: 100%;
    min-width: 15%;
    max-width: 15%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.download_img_field_scope_item img{
    max-height: 140px;
}
.download_img_field_scope_item_overlap{
    margin-left: -100%;
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 25px;
}
.download_img_field_scope_item_overlap_img{
    max-width: 37px;
}
.download_img_field_scope_item img{
    width: 100%;
}
.insert_data_field{
    background-color: white;
    display: flex;
    justify-content: center;
}
.insert_data_field_scope{
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.checkbox_group{
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 25px;
}
.checkbox_component{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

}
.insert_data_field_item_titel_input, .insert_data_field_item_description_input{
    width: 50%;
}
.insert_data_field_item_left{
    display: flex;
    align-items: center;
    justify-content: center;
}
.insert_data_field_item_right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.insert_data_field_item input1{
    width: 75%;
    height: 40px;
    border: 1px solid #666666;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    font-size: 19px;
    color: #A0A0A0;
    opacity: 1;
    text-align: left;
}
.insert_data_field_item textarea{
    width: 100%;
    height: 200px;
    border: 1px solid #666666;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    font-family: "Segoe UI";
    font-size: 17px;
    color: #000000;
    opacity: 1;
    text-align: left;
    resize: none;
    padding: 5px;
}
.add_post_btn_placement{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.add_post_btn_placement button{
    width: 30%;
    margin: 0 0;
}
.edit_post_btn_placement{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn_placement, .edit_post_btn_placement button{
    width: 30%;
}
.btn_placement{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.btn_placement_tow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add_post_dropdown{
    background-color: #eeeeee;
}
.add_post_dropdown_arrow{
    border-color: #ff6611 transparent transparent;
    border-width: 7.5px 7.5px 0;
}

@media all and (max-width: 600px){
    .download_img_field_scope_item_overlap{
        gap: 4px;
    }
    .download_img_field_scope_item_overlap image{
        width: 37px;
    }
    .download_img_field_scope_item_overlap_img{
    max-width: 30px;
}
    .download_img_field_scope_item {
        max-width: 20%;
    }
    .add_post_content{
        width: 90%;
        padding-top: 75px;
        padding-bottom: 75px;
    }
    .checkbox_group{
        flex-direction: column;
    }
    .download_img_field_scope_item{
        width: 40%;
    }
    .checkbox_group{
        gap: 15px;
    }
    .insert_data_field_item_titel_input, .insert_data_field_item_description_input{
        width: 100%;
    }
    .market_dropdown{
        width: 100%

    }
    .btn_placement button{
        width: 45%;
        padding: 0px;
    }
}
.new_login{
    width: 900px;
    height: 450px;
    text-align:center;
    margin: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    justify-content: center;
}
.info_box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background: url(../../static/login/Background_grey.svg);
    background-size: cover ;
}
.login_content{
    width: 75%;
    display: flex;
    flex-direction: row;
    margin: 5px;
    justify-content: center;
}
.login_content_left{
    min-height: 400px;
    flex: .5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 25px;
    background-color: rgb(255,107,0);

}
.login_content_left_items_top{
    flex: 1;
    height: 100%;
    width: 100%;
    //border: 2px solid #f60;
    padding: 7px 25px 0px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}
.login_content_left_items_down{
    width: 100%;
    //border: 2px solid #f60;
    padding: 0px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.login_content_left_items_component{
    width: 30%;
}
/*
.login_content_left_items h2{
    font: normal normal bold 45px/65px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

 */
/*
.login_content_left_items p{
    font: normal normal normal 21px/30px Segoe UI;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
}

 */

.login_content_left_item{
    display: flex;
    flex-direction: row;
    gap: 75px;

}
.login_content_left_item_progress_bar_step{
    width: 75px;
    height: 75px;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_content_left_item_progress_bar_text{
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_content_right{
    display: flex;
    flex-direction: column;
    flex:.5;
    background-color: rgb(255,255,255, 60%);

    justify-content: center;
}
.login_content_right_frame{
    min-height: 400px;
    display: flex;
    flex-direction: column;
    border: 2px solid #f60;
    padding: 25px;
    gap: 30px;
}
.login_content_right_item{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.login_content_right_item_component{
    width: 30%;
}
.login_content_right_item_btn_section{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.login_content_right_item_btn_section_right{
    width: 30%;
}
.login_content_right_item_btn_field_forgot_pass{
    flex-direction: row;
    justify-content: space-between;
}
.login_content_right_item_btn_field{
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
}
/*
.login_content_right_item h2{
    font: normal normal bold 30px/39px 'Roboto Condensed', sans-serif;
    letter-spacing: 0px;
    color: #FF6B00;
    text-transform: uppercase;
    opacity: 1;
}

 */
/*
.login_content_left h2{
    font: normal normal bold 30px/39px 'Roboto Condensed', sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
}
*/

.login_content_right_item_checkbox{
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.login_content_right_item_num_with_prefix{
    justify-content: flex-start;
    gap: 15px;
    flex-direction: row;
    width: 100%;
}
.login_content_right_item_num_with_prefix_item p{
    max-width: max-content;
    font-size: 15px;
    color: #737373;
}
#login_content_right_item_num_with_prefix_item_input{
    flex: .9;
}
.login_content_right_item_num_with_prefix_item{
    display: flex;
    align-items: center;
    justify-content: center;
}
.new_login_text{
    display: flex;
    flex-direction: column;

    flex: 0.6;
    border-radius: 10px;
    margin: 7.5% 5%;
    padding: 15px;
    background-color: #f60;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}
.new_login_text h2{
    color: white;
    margin-block-end: 0.41em;
}
#location_icon{
    width: 48px;
    margin: 0;
}
.new_login_group{
    border: 3px solid #f60;
    border-radius: 10px;
    margin: 7.5% auto;
    padding: 15px;
    flex: 0.4;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}
.new_input-field{
    width: 100%;
    height: 40px;
    padding: 10px 5px;
    margin: 0px 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border: 1px solid #999;
    //border-radius: 10px;
    outline: none;
    background: transparent;
    font-size: 15px;
}
.new_submit-btn{
    width: 80%;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin: 0px auto;
    background-color: #f60;
    border: 0;
    outline: none;
    //border-radius: 10px;
    font-size: 14.5pt;
    font-weight: bold;
    color: white;
}
.create_new_account_btn_1{
    width: 80%;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    background-color: #fff;
    border: 2px solid #666;
    outline: none;
    //border-radius: 10px;
    font:normal normal bold 17px/24px Segoe UI;
    color: #A0A0A0;
}

.create_new_account_btn{
    color: #666;
    letter-spacing: 0px;
    font: normal normal bold 17px/24px Segoe UI;
    text-align: center;
    opacity: 1;
    background-color: #FFFFFF;
    padding: 15px;
    font-weight: bold;
    display: block;
    border: 0px solid #666;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    height: 45px;

    padding: 10px 20px;
    cursor: pointer;
    display: block;
    margin: 0px auto;
    outline: none;
    font-weight: bold;
}
.login_btn{
    width: 100%;
    color: #fff;
    letter-spacing: 0px;
    font: normal normal bold 17px/24px Segoe UI;
    text-align: center;
    opacity: 1;
    background-color: #f61;
    padding: 15px;
    font-weight: bold;
    display: block;
    border: 0px solid #666;
    box-shadow: 0px 3px 6px #00000029;
    height: 45px;

    padding: 10px 20px;
    cursor: pointer;
    display: block;
    outline: none;
    font-weight: bold;
}
/*
.new_submit-btn_test:hover{
    color: white;
    cursor: pointer;
}
.new_submit-btn_test:before{
    transition: .5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background-color: #666;
    border-radius: 10px;
}
.new_submit-btn_test:hover:before{
    transition: .5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}

 */
.new_register_btn_login{
    width: 80%;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin: 0px auto;
    background-color: #666;
    border: 0;
    outline: none;
    //border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    color: white;
    filter: drop-shadow(2px 3px 6px rgba(0, 0, 0, 0.161));
}
.new_register-btn_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}
.line_login{
    align-items: center;
    border-bottom: 1px solid #dadde1;
    display: flex;
    margin: 20px 16px;
    text-align: center;
}
span_login{
    color: red;
    font-size: 14px;
    position: relative;
    text-decoration: none;
    margin: 0px auto;
}
.span_container_forgot_pass{
    text-align: center;
}
span_forgot_pass{
    color: #f61;
    font-size: 14px;
    position: relative;
    text-decoration: none;
    cursor: pointer;
}

span_forgot_pass:hover {
    color: #f60;
}

span_forgot_pass::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f60;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

span_forgot_pass:hover::before {
    transform: scaleX(1);
}
.ui_header_title{
    font-family: SFProDisplay-Bold, Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-top: -15px;
    padding: 18px 16px 18px 18px;
    color: #1b9f87;
}
.ui_header{
    flex: 1;

    border-bottom: 1px solid #999;
}
.ui_content{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #999;
}
.ui_content_text{
    margin-top: 18px;
    margin-bottom: 18px;
    font-family: SFProText-Regular, Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 20px;
    align-self: flex-start;
    text-align: left;
    color: #1b9f87;
}
.mail_sent{
    width: 900px;
    height: auto;
    text-align:center;
    margin: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    justify-content: center;
}
.forgot_pass{
    width: 450px;
    height: auto;
    text-align:center;
    margin: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    justify-content: center;
}
.login_content_right_item_left{
    flex: .065;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.login_content_right_item_right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.login_content_right_item_right p{
    max-width: max-content;
}
.login_content_right_item_digit{
    width: 50px;
    height: 50px;
    background-color: #ffffff;
}
.login_content_right_item_digit_input{
    width: 100%;
    height: 100%;
    background: transparent;
    text-align: center;
    font-size: 17px;
    font-family: TradeGothic,sans-serif;
    padding: 10px 5px;
    border: 0px;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

@media all and (max-width: 992px){
    /*
    .new_login{
        width: 100%;
        height: 100%;
        flex-direction: column-reverse;
    }
    .forgot_pass{
        width: 90%;
        height: 100%;
    }
    .new_login_text{
        flex: 1;
        width: 90%;
    }
    .new_login_group{
        flex: 1;
        width: 90%;
    }
    .new_register_btn_login{
        font-size: 12px;
    }
    .mail_sent{
        width: 100%;
        height: 100%;
    }
    .login_content{
        width: 90%;
    }

     */
}
@media all and (max-width: 1200px){
    .login_content{
        width: 80%;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .register_window{
        width: 50%;
    }
    .login_content_left_items_component{
        width: 45%;
    }
    .login_content_right_item_btn_section_right{
        width: 45%;
    }
    .login_content_right_forgot_pass, .login_content_right_register{
        flex: 1;

    }
    .login_content_right_frame_forgot_pass, .login_content_right_frame_register{
        min-height: 300px;
    }
}
@media all and (max-width: 992px){
    .login_content{
        width: 80%;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .login_content_left_items_component{
        width: 45%;
    }
    .login_content_right_item_btn_section_right{
        width: 45%;
    }
    .login_content_right_forgot_pass, .login_content_right_register{
        flex: 1;

    }
    .login_content_right_frame_forgot_pass, .login_content_right_frame_register{
        min-height: 300px;
    }
}
@media all and (max-width: 768px){
    .login_content{
        width: 80%;
        flex-direction: column-reverse;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .login_content_right_frame{
        gap: 25px;
        min-height: 300px;
        padding: 15px;
    }
    .login_content_left{
        min-height: 75px;
    }
    .login_content_left_items{
        gap: 25px;
        padding: 15px;
    }
    .login_content_left_items_down{
        align-items: flex-end;
        padding: 0px 15px 15px 15px;
    }
    .login_content_left_items_top{
        padding: 7px 15px 0px 15px;
    }
}
@media all and (max-width: 600px){
    .login_content{
        width: 90%;
        flex-direction: column-reverse;
    }
    .login_content{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .login_content_right_frame{
        gap: 25px;
        min-height: 300px;
        padding: 15px;
    }
    .login_content_left{
        min-height: 75px;
    }
    .login_content_left_items{
        gap: 25px;
        padding: 15px;
    }
    .login_content_right_item_btn_section_right{
        width: 45%;
    }
    .login_content_left_items_component{
        width: 45%;
    }
    .login_content_left_items_down{
        align-items: flex-end;
        padding: 0px 15px 15px 15px;
    }
    .login_content_left_items_top{
        padding: 7px 15px 0px 15px;
    }
    .login_content_right_item_component{
        width: 45%;
    }
}
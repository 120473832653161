.parcel{
    --bg_color : #EEEEEE;
    --img_body_bg_color : #FFFFFF;
    width: 200px;
    height: 300px;
    padding-bottom: 15px;
    background-color: var(--bg_color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    flex-shrink: 1;
}
.parcel img{
    width: 75px;

}
.parcel_img_body{
    width: calc(100% - 30px);
    height: 40%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--img_body_bg_color);
}
.parcel_img_body div{
    width: 75px;
    margin-left: -75px;
    text-align: center;
}
.parcel_img_body p{
    color: #ff6b00;
    font-size: 30px;
}
.parcel_text_component{
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
}
.parcel_text_component p{
    white-space: nowrap;
    overflow: hidden;
}
.parcel_text_component_timeLocation{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
/*
.parcel_text_component p{
    font: normal normal normal 14px/18px Segoe UI;
    letter-spacing: 0px;
    color: #A0A0A0;
    opacity: 1;
}*/
.parcel_text_component h2{
    font: normal normal bold 17px/22px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.parcel_text_component img{
    width: 12px;
    height: 12px;
    margin-right: -12.5px;
}
.parcel_text_component div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
/*
.parcel button{
    color: #FF6B00;
    letter-spacing: 0px;
    font: normal normal bold 17px/24px Segoe UI;
    text-align: center;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 7px;
    padding: 15px;
    font-weight: bold;
    display: block;
    //margin: 0px auto;
    border: 2px solid #FF6B00;
    box-shadow: 0px 3px 6px #00000029;
    width: calc(100% - 60px);
    height: 45px;

    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin: 25px auto 0 auto;
    outline: none;
    font-weight: bold;
}*/
.parcel button{
    box-shadow: 0px 3px 6px #00000029;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #FF6B00;
    width: calc(100% - 30px);
    color: #FF6B00;
}

.parcel button:hover {
    background-color: #A0A0A0;
    color: #FFFFFF;
}
.details_btn_field_one_btn{
    width: 30%;
}
@media all and (max-width: 992px){
    .parcel{
        width: 180px;
    }
}
@media all and (max-width: 768px){
    .parcel{
        width: 30%;
    }

}
@media all and (max-width: 600px){
    /*
    .parcel{
        width: 47%;
    }
    .parcel button{
        padding: 5px;
        margin: 15px auto 0 auto;
        width: calc(100% - 40px);
    }
    .parcel img{
        width: 50px;
    }

     */
    .parcel{
        width: 47%;
    }
}
.package_station{
    --locker_width: 45vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.package_station_content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

}
.terminal_img img{
    width: var(--locker_width);
}
.overlay_terminal_img{
    width: var(--locker_width);
    margin-left: -100%;
}
.img_flex{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.columns{
    width: var(--locker_width);/*500px*/
    /*height: 50vw;374.3px*/
    height: calc(var(--locker_width)*0.748611111);
    border: 2.5px solid #707070;
    display: flex;
    flex-direction: row;
}
.column{
    width: 20%;
    height: 100%;
}
.row_a{
    width: 100%;
    height: calc(0.735/1.84*100%);
}
.row_e{
    width: 100%;
    height: calc(0.370/1.84*100%);
}
.row_d{
    width: 100%;
    height: calc(0.447/1.783*100%);
}
.row_j{
    width: 100%;
    height: calc(0.226/1.783*100%);
}
.row_i{
    width: 100%;
    height: calc(0.108/1.783*100%);
}
.row_f{
    width: 100%;
    height: calc(0.345/1.834*100%);
}
.row_x{
    width: 100%;
    height: calc(0.744/1.834*100%);
}
.row_b{
    width: 100%;
    height: calc(0.745/1.834*100%);
}
.row_c{
    width: 100%;
    height: calc(0.450/1.8*100%);
}
.row_h{
    width: 100%;
    height: calc(0.225/1.8*100%);
}
.free{
    background-color: rgb(102, 102, 102, 30%);
}
.not_free{
    background-color: #BD451C66;
}
.not_free_admin{
    cursor: pointer;
    background-color: #BD451C66;
}
.not_free_admin:hover{
    background-color: rgb(255, 102, 0, 60%)
}
.overdue{
    cursor: pointer;
    background-color: #BD451C;
}
.overdue_admin{
    cursor: pointer;
    background-color: #BD451C;
}
.overdue_admin:hover{
    background-color: rgb(255, 102, 0, 60%)
}

.not_free_out_of_order{
    background-color: #c5ba00;
}
.my_style{
    /*
    border: 1.5px solid red;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 2px;
    padding-top: 2px;
    */
}
.border_right_bottom{
    border-right: 2.5px solid #707070;
    border-bottom: 2.5px solid #707070;
}
.border_right{
    border-right: 2.5px solid #707070;
}
.border_bottom{
    border-bottom: 2.5px solid #707070;
}
.terminal_explain{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
}
.terminal_explain_items{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.terminal_explain_item{
    display: flex;
    gap: 20px;
}
.terminal_explain_admin{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
}
.terminal_explain_admin_item{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.terminal_explain_admin_item div{
    min-width: 25px;
}
.terminal_explain_admin_item h4{
    font-size: 21px;
}
/*
.terminal_explain h4{
    font: normal normal bold 20px/27px 'Roboto Condensed', sans-serif;
    letter-spacing: 0px;
    color: #737373;
    opacity: 1;
}

 */
.terminal_explain_admin h4{
    color: #737373;
}
.free_box{
    width: 25px;
    height: 25px;
    border: 2.5px solid #707070;
    background-color: rgb(102, 102, 102, 30%);
    border-radius: 50%;
}
.not_free_box{
    width: 25px;
    height: 25px;
    border: 2.5px solid #707070;
    background-color: rgb(189, 69, 28, 40%);
    border-radius: 50%;
}
.not_free_box_overdeu{
    width: 25px;
    height: 25px;
    border: 2.5px solid #707070;
    background-color: #BD451C;
    border-radius: 50%;
}

.not_free_box_out_of_order{
    width: 25px;
    height: 25px;
    border: 2.5px solid #707070;
    background-color: #c5ba00;
    border-radius: 50%;
}

.my_style{

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5px;
}

.my_style p{

    }
.my_style p{
        font-size: 12px;
        margin-top: -0px;
        //font-weight: bold;
    }

.xs img{
    height: 60%;
    margin-top: 5px;
}
.s img{
    height: 28.67256637%;
    margin-top: 5px;
}
.m img{
    height: 17.513513%;
    margin-top: 5px;
}
.l img{
    height: 14.49664387%;
    margin-top: 5px;
}
.xl img{
    height: 8.816326272%;
    margin-top: 5px;
}

/* Adlershof AH Terminal */
.column_ah{
    width: 100%;
    height: 100%;
}
.row_ah{
    width: 100%;
    //height: calc(0.92/1.84*100%);
    height: 25%;
}
.row_ah img{
    height: 15%;
    margin-top: 5px;

}
/* Adlershof AH Terminal */
@media all and (max-width: 600px){
    .my_style p{
            font-size: 12px;
            margin-top: -2px;
            //font-weight: bold;
    }
    .my_style{
        padding-left: 2px;
    }
    .xs img{
        height: 95%;
        margin-top: 0px;
        padding-top: 2px;
    }
    .s img{
        height: 56%;
        margin-top: 5px;
    }
    .m img{
        height: 34%;
        margin-top: 5px;
    }
    .l img{
        height: 28%;
        margin-top: 5px;
    }
    .xl img{
        height: 16%;
        margin-top: 5px;
    }

}
@media all and (max-width: 768px){
    .terminal_explain{
    }
}



.reset_pass{
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(../../static/login/Background_grey.svg);
    background-size: cover ;
}
.reset_pass_content{
    width: 75%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
}
.reset_pass_content_item{
    min-height: 400px;
    border: 2px solid #f60;
    padding: 25px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: .5;
    background-color: rgb(255,255,255, 60%);
}
.reset_pass_input{
    width: 100%;
    height: 40px;
    padding: 10px 5px;
    margin: 0px 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #999;
    //border-radius: 10px;
    outline: none;
    background: transparent;
    font-size: 15px;
}
.reset_pass_btn{
    width: 100%;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin: 0px auto;
    background-color: #f60;
    border: 0;
    outline: none;
    //border-radius: 10px;
    font-size: 14.5pt;
    font-weight: bold;
    color: white;
}
.btn_field{
    width: 30%;
}
@media all and (max-width: 1200px){
    .reset_pass_content{
            width: 50%;
    }
    .reset_pass_content_item {
        flex: 1;
        padding: 15px;
        gap: 25px;
        min-height: 300px;
    }
}
@media all and (max-width: 992px){
    .reset_pass_content{
            width: 80%;
    }
    .reset_pass_content_item {
        flex: 1;
        padding: 15px;
        gap: 25px;
        min-height: 300px;
    }
}
@media all and (max-width: 768px){
    .reset_pass_content{
        width: 80%;
    }
    .reset_pass_content_item {
        flex: 1;
        padding: 15px;
        gap: 25px;
        min-height: 300px;
    }
}
@media all and (max-width: 600px){
    .reset_pass_content{
        width: 90%;
    }
    .reset_pass_content_item {
        flex: 1;
        padding: 15px;
        gap: 25px;
        min-height: 300px;
    }
    .global_style_h2_light_text{
        font-size: 24pt;
    }
    .btn_field{
        width: 45%;
    }
}

.start_site_content{
    display: flex;
    flex-direction: column;
    flex: 1;
    -webkit-align-items: center;
}
.start_site_content_item{
    display: flex;
}
.start_site_content_item_mobileStoerer{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #dbdbda;
    /*height: 250px;
    width: 100%;
    background: url(../../static/start_site/Störer mobil.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;*/
}
.start_site_content_item_mobileStoerer div{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.start_site_content_item_mobileStoerer img{
    width: 96px;
}
.stoerer_rund{
    display: flex;
    justify-content: flex-start;
    margin-top: -425px;
    margin-left: 30%;

}
.stoerer_rund_klein{
    display: flex;
    justify-content: flex-start;
    margin-left: calc(30% + 400px);
    margin-top: -175px;
    margin-right: 25px;

}
.stoerer_rund_klein p{
    text-align: center;
}
.stoerer_rund_klein img{
    width: 175px;
    width: 175px;
}
.stoerer_rund a{
    color: rgb(189, 69, 28);
}
.start_site_content_item_mobileStoerer a{
    color: rgb(189, 69, 28);
}
.stoerer_rund img{
    width: 400px;
    height: 400px;
    z-index: 10000;
    transform: rotate(15deg);

}
.stoerer_rund div{
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    margin-left: -405px;
    z-index: 10000;
    transform: rotate(15deg);
}
.stoerer_rund_klein div{
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 175px;
    height: 175px;
    margin-left: -175px;

    transform: rotate(15deg);
}
.stoerer_rund_img{
    background: url(../../static/start_site/Störer rund.svg);
}
.start_site_content_item_mobileStoerer p{
    text-align: center;
}
.start_site_content_item_package_station_title{
    width: 75%;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
    gap: 10px;
    margin-bottom: 20px;
}
.start_site_content_item_markt{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 60px;
    flex-direction: column;
    background-color: rgb(238, 238, 238, .5);
    align-items: center;
}
.start_site_content_item_info{
    width: 100%;
    background: url(../../static/market/posts_section_background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
}
.start_site_content_item_info_box{
    width: 75%;
    background-color: #A0A0A0;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.start_site_content_item_info_box h4{
    color: #FFFFFF;
}
.start_site_content_item_info_box_items{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 75px;
}
.start_site_content_item_info_box_items_item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
}
.start_site_content_item_info_box_items_item a{
    color: #FFFFFF;
}
#location_icon{
    padding-left: 2px;
    padding-right: 2px;
}
.start_site_content_item_info_box_items_item img{
    width: 48px;
}
.start_site_content_item_info_box_items_item p{
    color: #FFFFFF;
}
.start_site_content_item_markt_titleSection{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    gap: 10px;
}
.start_site_content_item_markt_guideInfo{
    width: 75%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 15px;
}
.start_site_content_item_markt_contentSection{
    width: 75%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    padding-top: 20px;
}
.start_site_content_item_markt_contentSection_left{
    display: flex;
    flex-direction: column;
    flex: .5;
    gap: 15px;
}
.start_site_content_item_markt_contentSection_left_posts{
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    height: 275px;
    width: 100%;
    overflow: scroll;
    overflow-y: hidden;
}
.start_site_content_item_markt_contentSection_right_btnField{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.start_site_content_item_markt_btnField{
    width: 75%;
}
.start_site_content_item_markt_btnField button{
    width: 250px
}
.start_site_content_item_markt_contentSection_right_btnField button{
    width: auto;
    margin: 0;
}
.start_site_content_item_markt_contentSection_right{
    height: 300px;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex: .5;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}
.start_site_content_item_header{
    flex-direction: column;
    width: 100%;
}
.start_site_content_item_faq_background{
    width: 100%;
    background-color: #BD451C;
    display: flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
}
.start_site_content_item_faq_field{
    width: 75%;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -webkit-justify-content: center;
}
.start_site_content_item_package_station{
    width: 75%;
    flex-direction: row;
    -webkit-justify-content: center;
    -webkit-align-items: flex-start;
    gap: 2%;
    margin-top: 30px;
    margin-bottom: 60px;
    align-items: flex-start;
}
.start_site_content_item_package_station_stretch{
    width: 75%;
    flex-direction: row;
    -webkit-justify-content: center;
    -webkit-align-items: flex-start;
    gap: 2%;
    margin-top: 30px;
    margin-bottom: 60px;
    align-items: stretch;
}
.start_site_content_item_package_station_item{
    min-height: 100%;
    flex: .5;
    display: flex;
    flex-direction: column;
    -webkit-align-items: flex-start;
    justify-content: space-between;
    gap: 25px;
}
.start_site_content_item_package_station_item_terminalsListItem{
    background-color: rgb(160, 160, 160, 10%);
    align-items: center;
    padding: 25px 15px 5px 15px;
    gap: 40px;
}
.start_site_content_item_package_station_item_terminalsListItem_btnSection{
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 0px;
}
.start_site_content_item_package_station_item_terminalsListItem_contentSection{
    padding: 0px 30px 35px 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.hide_content{
    display: none;
}
.start_site_content_item_package_station_item_terminalsListItem_contentSection_terminal{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.start_site_content_item_package_station_item_terminalsListItem_details{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding-right: 30px;
}
.start_site_content_item_package_station_item_terminalsListItem_details_item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.start_site_content_item_package_station_item_terminalsListItem_details_item img{
    width: 20px;
}
.start_site_content_item_package_station_item_terminalsListItem_details_item div{
    display: flex;
    flex-direction: column;
}
.fa-own{
    color: #FF6B00;
}
.start_site_content_item_package_station_item_sizesTable{
    display: flex;
    gap: 20px;
    width: 100%;
}
.start_site_content_item_package_station_item_sizesTable_left{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.start_site_content_item_package_station_item_sizesTable_right{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.start_site_content_item_package_station_item_terminal_explain{
    flex-direction: row;
    height: 100%;
}
.start_site_content_item_package_station_item_labels{

}
.start_site_content_item_main_field_background{
    width: 100%;
    background-color: #eee;
    display: flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
}
.start_site_content_item_guide_background{
    width: 100%;
    background-color: #fff;
    display: flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
}
.start_site_content_item_guide{
    width: 75%;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row-reverse;
    -webkit-align-items: flex-start;
    -webkit-justify-content: center;
    //gap: 2%;
    gap: 40px;
}
.start_site_content_item_guide_item{
    display: flex;
    flex-direction: column;
    gap: 25px;
    -webkit-align-items: flex-start;
    -webkit-justify-content: center;
    flex: .5;
}
.start_site_content_item_guide_item_text_centering{
    align-items: center;
    justify-content: center;
}
.start_site_content_item_guide_item_text_centering h2{
    text-align: center;
}
.start_site_content_item_guide_item_btn_field{
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    -webkit-align-items: center;
}
.start_site_content_item_guide_item_btn_field button{
    width: 250px;
    margin: 0;
}
.start_site_content_item_main_field{
    width: 75%;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    gap: 20px;
}
.start_site_content_item_main_field_title{
    display: flex;
    flex-direction: column;
    -webkit-justify-content: center;
    -webkit-align-items: center;
}
.start_site_content_item_main_field_btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    -webkit-gap: 25px;
    gap: 25px;
}
.start_site_content_item_main_field_btns_item{
    width: 15%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 6px #00000029;
    opacity: 0.8;
    padding: 10px;
    display: flex;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.start_site_registration_link{
    display: flex;
    -webkit-justify-content: center;
}
.inbox_notification{
    color: white;
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
}
.inbox_notification img{
    width: 100%;
    height: 100%;
}
.inbox_notification .notifications_badge{
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: #BD451C;
    color: white;
    font-size: 15px;
    font-weight: bold;
    margin-top: -13.5px;
    margin-right: -13.5px;
}
.start_site_content_item_main_field_btns_item img{
    width: 100%;
    height: 60px;
}
.start_site_content_item_location_field{
    padding-top: 30px;
    padding-bottom: 30px;
    flex-direction: row;
    -webkit-align-items: flex-start;
    -webkit-justify-content: center;
    gap: 75px;
    background-color: #FFFFFF;
    border: 3px solid #FF6B00;
    padding: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.start_site_content_item_location_field_item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    -webkit-justify-content: center;
    gap: 25px;
}
.start_site_content_item_location_field_item h3{
    font: normal normal bold 26px/37px Segoe UI;
    letter-spacing: 0px;
    color: #737373;
}
.start_site_content_item_location_field img{
    height: 50px;
    fill: #737373;
}
.bsr_slogan_img{
    display: flex;
    flex-direction: column;
    flex: 0.7;
}
.bsr_slogan{
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    -webkit-justify-content: center;
    -webkit-align-items: flex-start;
    flex: 1;
    background: url(../../static/start_site/Banner Bild.svg);
    background-size: cover ;
}
.bsr_slogan_btn{
    display: flex;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    -webkit-align-items: flex-end;
    width: 400px;
}
.bsr_slogan p{
    color: #FFFFFF;
    text-align: center;
}
.bsr_slogan button:hover {
    background-color: #A0A0A0;
    color: #FFFFFF;
}
.guide_item{

    display: flex;
    flex-direction: row;
    max-width: max-content;
    gap: 30px;
}
.guide_item_step{
    width: 65px;
    height: 65px;
    background-color: #a0a0a0;
    display: flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}
.guide_item_text{
    display: flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}
.guide_item_step h2{
    font: normal normal bold 24px/10px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.guide_item_text h3{
    min-width: max-content;
    font: normal normal normal 26px/37px Segoe UI;
    letter-spacing: 0px;
    color: #737373;
    opacity: 1;
}
#start_site_register_btn:hover{
    color: #ff6611;
}
.start_site_content_item_guide_item_right{
    gap: 30px;
}
.start_site_content_item_package_station_item_sizesTable_otherScreens{
    display: none;
}
@media all and (max-width: 1200px) {
    .start_site_content_item_location_field {
        gap: 30px;
    }
    .start_site_content_item_guide {
        width: 80%;
    }
    .guide_item{
        gap: 15px;
    }
    .guide_item_step{
        width: 50px;
        height: 50px;
    }
    .start_site_content_item_faq_field{
        width: 80%;
    }
    .start_site_content_item_package_station_item_sizesTable_laptopScreen{
        display: flex;
    }
    .start_site_content_item_package_station_item_sizesTable_otherScreens{
        display: none;
    }
    .terminal_explain_item{
        gap: 10px;
    }
    .start_site_content_item_package_station_item_sizesTable_otherScreens{
        display: none;
    }
    .stoerer_rund{
        margin-left: 35%;
    }
    .stoerer_rund_klein{
        margin-left: calc(35% + 400px);
    }
    .start_site_content_item_package_station{
        width: 80%;
    }
}
@media all and (max-width: 992px) {
    .start_site_content_item_location_field {
        gap: 30px;
    }
    .start_site_content_item_guide {
        width: 80%;
    }
    .guide_item{
        gap: 15px;
    }
    .guide_item_step{
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
    }
    .start_site_content_item_package_station{
        width: 80%;
        flex-direction: column;
        gap: 20px;
    }
    .start_site_content_item_faq_field{
        width: 80%;
    }
    .start_site_content_item_main_field{
        width: 80%;
    }
    .start_site_content_item_main_field_btns_item{
        width: 20%;
    }
    .start_site_content_item_info_box_items{
        flex-direction: column;
        gap: 30px;
    }
    .stoerer_rund{
        margin-left: 42.5%;
    }
    .stoerer_rund_klein{
        margin-left: calc(42.5% + 400px);
    }
    .start_site_content_item_package_station_item_terminalsListItem_details_item p{
        font-size: 14px;
    }
}
@media all and (max-width: 768px){
    .start_site_content_item_guide{
        width: 80%;
        flex-direction: column-reverse;
        gap: 25px;
        justify-content: center;
        align-items: center;
    }
    .start_site_content_item_package_station{
        width: 80%;
        flex-direction: column;
        gap: 20px;
    }
    .start_site_content_item_faq_field{
        width: 80%;
    }
    .start_site_content_item_location_field {
        gap: 30px;
    }
    .start_site_content_item_guide_item_right{
        flex-direction: row;
        justify-content: center;
        gap: 0px;

    }
    .guide_item{
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: center;
        min-width: 27.5%;
    }
    .guide_item h4{
        text-align: center;
    }
    .guide_item_step{
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
    }
    .start_site_content_item_info_box_items{
        flex-direction: column;
        gap: 30px;
    }
    .start_site_content_item_info_box{
        width: 80%;
    }
    .start_site_content_item_package_station_item{
        width: 100%;
    }
    .start_site_content_item_markt_contentSection{
        width: 80%;
        flex-direction: column-reverse;
    }
    .start_site_content_item_markt_contentSection_left{
        width: 100%;
    }
    .start_site_content_item_markt_btnField{
        width: 80%;
        margin-top: 40px;
    }
    .start_site_content_item_info_box_items{
        flex-direction: column;
        gap: 30px;
    }
    .start_site_content_item_mobileStoerer{
        display: flex;
        padding-bottom: 25px;
    }
    .start_site_content_item_mobileStoerer p{
        width: 60%;
    }
    .stoerer_rund{
        display: none;
    }
    .stoerer_rund_klein{
        margin-left: 0px;
        justify-content: flex-end;
    }
    #stoerer_rund_klein{
        display: none;
    }
    .start_site_content_item_package_station_item_terminalsListItem_details_item p{
        font-size: 17px;
    }
}
@media all and (max-width: 600px){
    .bsr_slogan{
        padding-left: 5%;
        padding-right: 5%;
        background-size: cover;
    }
    .start_site_content_item_main_field{
        width: 90%;
    }
    .teaser_h4{
        text-align: center;
    }
    .start_site_content_item_main_field_btns{

        flex-wrap: wrap;
    }
    .start_site_content_item_main_field_btns_item{
        width: 40%;
    }
    .start_site_content_item_location_field{
        flex-direction: column;
        gap: 25px;
    }
    .start_site_content_item_guide_item_right{
        flex-direction: column;
        gap: 10px;
    }
    .guide_item{
        flex-direction: row;
    }
    .guide_item h4{
        text-align: left;
    }
    .guide_item_step{
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
    }
    .guide_item_text{
        justify-content: flex-start;
        align-items: flex-start;
    }
    .guide_item_text h4{
        text-align: left;
        font-size: 17px;
    }
    .start_site_content_item_guide_item_text_centering{
        width: 90%;
    }
    .start_site_content_item_guide{
        width: 90%;
        margin-top: 10px;
    }
    .start_site_content_item_guide_item{
        flex: 1;
    }
    .start_site_content_item_package_station{
        width: 90%;
        flex-direction: column;
        gap: 25px;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .start_site_content_item_package_station_item{
        flex: 1;
        align-items: center;
    }
    .start_site_content_item_faq_field{
        width: 90%;
    }
    .start_site_content_item_markt_contentSection{
        width: 90%;
        flex-direction: column-reverse;
        gap: 30px;
    }
    .start_site_content_item_markt_guideInfo{
        width: 90%;
    }
    .start_site_content_item_markt_contentSection_right_btnField button{
        width: auto;
    }
    .start_site_content_item_markt_contentSection_right{
        gap: 15px;
    }
    .start_site_content_item_markt_contentSection_left_posts{
        justify-content: space-between;
    }
    .start_site_content_item_markt_titleSection h2{
        text-align: right;
    }
    .start_site_content_item_package_station_item_terminal_explain{
        flex-direction: column;
        gap: 15px;
    }
    .start_site_content_item_info_box_items{
        flex-direction: column;
        gap: 30px;
    }
    .start_site_content_item_info_box{
        width: 90%;
        padding: 20px 15px 20px 15px;
    }
    .start_site_content_item_info_box_items_item{
        gap: 15px;
        flex-direction: row;
    }
    .market_page_content_posts_section_components_posts_row_item{
        width: 47%;
    }
    .start_site_content_item_markt_contentSection_left{
        width: 100%;
    }
    .start_site_content_item_markt_contentSection_left_posts{
        gap: 5vw;
        margin-bottom: 40px;
    }
    .terminal_explain_items{
        gap: 30px;
        flex-direction: row;
    }
    .start_site_content_item_markt_titleSection{
        width: 90%;
        padding-bottom: 30px;
    }
    .start_site_content_item_package_station_title{
        width: 90%;
    }
    .start_site_content_item_markt{
        padding-bottom: 30px;
    }
    .start_site_content_item_markt_btnField{
        margin-top: 0px;
    }
    .start_site_content_item_mobileStoerer{
        display: flex;
        padding-bottom: 25px;
    }
    .start_site_content_item_mobileStoerer p{
        width: 60%;
    }
    .stoerer_rund{
        display: none;

    }
    .stoerer_rund_klein{
        display: none;
    }
    .start_site_content_item_package_station_item_terminalsListItem_details_item p{
        font-size: 14px;
    }
    /*
    .start_site_content_item_header{
        flex-direction: column-reverse;
    }

    .start_site_content_item_main_field {
        width: 90%;
    }
    .start_site_content_item_location_field{
        width: 90%;
        flex-direction: column;
        gap: 50px;
    }
    .start_site_content_item_location_field_item{
        justify-content: center;
        align-items: center;
    }
    .start_site_content_item_location_field img{
        height: 100px;
    }
    .start_site_content_item_guide{
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }
    .start_site_content_item_location_field_item h3{
    font: normal normal bold 26px/37px Segoe UI;
    letter-spacing: 0px;
    color: #737373;
    text-align: center;
    }
    .start_site_content_item_location_field_item h4{
        font: normal normal normal 21px/32px Segoe UI;
        letter-spacing: 0px;
        color: #737373;
        text-align: center;
        max-width: max-content;
    }
    .start_site_content_item_guide_item_left{
        align-items: flex-start;
    }
    .start_site_content_item_package_station h2{
        font: normal normal bold 30px/50px Segoe UI;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
    }
    .start_site_content_item_package_station h3{
        font-size: 3rem;
        text-align: center;
    }
    .start_site_content_item_package_station{
        width: 90%;
        flex-direction: column;
        gap: 25px;
    }
    .start_site_content_item_faq_field{
        width: 90%;
    }
    .start_site_content_item_main_field_btns_item{
        width: 100px;
        height: 100px;
    }
    .start_site_content_item_main_field_btns_item img{
        width: 100%;
        height: 70%;
        padding: 5px;
    }
    .start_site_content_item_guide_item{
        width: 90%;
        align-items: center;
        justify-content: center;
    }
    .start_site_content_item_guide_item_left{
        width: 100%;
    }
    .start_site_content_item_guide_item_right{
        width: 100%;
        display: none;
    }
    .start_site_content_item_guide_background h2{
        font-size: 3.5rem;
    }
    .start_site_content_item_guide_background h4{
        font-size: 2.4rem;
    }
    .start_site_content_item_guide_background p{
        font-size: 1.5rem;
        hyphens: auto;
        language: de;
    }
    .start_site_content_item_package_station h4{
        font-size: 2.4rem;
    }
    .start_site_content_item_package_station_item p{
        font-size: 1.5rem;
        hyphens: auto;
        language: de;
    }

     */
}


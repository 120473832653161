.cookie_banner_container {
  /* default settings */
  bottom: 0px;
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 1000000;
  /* extra settings */
  padding: 10px;
  min-height: 64px;
  background: #fff;
  text-align: left;
  box-shadow: 0 0 6px 6px #00000029;
  display: inline-block;
}

.cookie_banner_button_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.cookie_banner_button {
  color: #fff;
  background-color: #f60;
  text-decoration: none;
  flex: 1 1 0;
  text-align: center;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  margin: 10px 15px;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
}

.cookie_banner_overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.3);
}
.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #737373   ;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.popup-inner{
    position: relative;
    padding: 30px;
    width: 100%;
    max-width: 600px;
    background-color: #FF6600;
}
.profile_content{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile_content_item{
    display: flex;
}
.profile_personals{
    width: 75%;
    margin-top: 100px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 50px;
}
.profile_personals_item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}
.profile_content_item_components{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile_content_item_gray_background{
    background-color: #eee;
}
.profile_content_item_components_content{
    width: 75%;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.profile_content_item_components_content_items{
    display: flex;
    flex-direction: row;
    gap: 25px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
/*
.profile_content_item_components_content h2{
    font: normal normal bold 30px/47px Segoe UI;
    letter-spacing: 0px;
    color: #000;
    opacity: 1;
}

 */
.profile_personals_item_edit_icon{
    cursor: pointer;
    width: 17.5px;
}
.profile_personals_item div{
    border-bottom: 1px solid #b9b9b9;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 120px;
}
.profile_personals_item h3{
    font: normal normal normal 18px/25px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
/*
.profile_personals_item h1{
    font: normal normal bold 45px/62px Segoe UI;
    letter-spacing: 0px;
    color: #FF6B00;
    opacity: 1;
}

 */
.profile_personals_item_img{
    width: 200px;

}
.info_box_1{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}
.info_box_green_background{
    background-color: #f60;
}
.edit_personals_field{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 2px;
}
.edit_personals_field_item{
    flex: 1;
}
.personals_container{
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
}
.numberVerificationError{
    font-family: TradeGothic,sans-serif;
    color: #FF6B00;
    font-size: 14px;
    text-decoration: none;
}
.personals_container_section{
    display: flex;
    flex: 0;
    flex-direction: row;
    gap: 100px;
    justify-content: center;
    padding-left: 20px;
}
.personals_container_labels{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
}
.personals_container_labels_item{
    flex: 1;
    display: flex;
    align-items: center;
}
.personals_container_values{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
}
.personals_container_values_item{
    flex: 0;
    display: flex;
    align-items: center;
}
.personals_input_field{
    width: 100%;
    height: 40px;
    padding: 10px 5px;
    margin: 5px 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border: 1px solid #999;
    border-radius: 10px;
    outline: none;
    background: transparent;
    font-size: 15px;
}
.btn_section{
    width: 30%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    margin-top: 10px;
    margin-bottom: 10px;
}
.submit_btn{
    width: 160px;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin: 5px auto;
    background-color: #f60;
    border: 0;
    outline: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
}
.cancel_btn{
    width: 160px;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin: 5px auto;
    background-color: darkred;
    border: 0;
    outline: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
}
.modal_group_item_phoneNrInput{
    flex-direction: row;
}
@media all and (max-width: 1200px){
    .profile_personals{
        width: 80%;
        margin-top: 75px;
    }
    .profile_personals_item_img{
        width: 150px;
    }
    .profile_content_item_components_content{
        width: 80%;
    }
    .profile_content_item_components_content_items{
        gap: 15px;
    }
    .btn_field{
        width: 45%;
    }
}
@media all and (max-width: 992px){
    .profile_personals{
        width: 80%;
        margin-top: 75px;
    }
    .profile_personals_item_img{
        width: 150px;
    }
    .profile_content_item_components_content{
        width: 80%;
    }
    .profile_content_item_components_content_items{
        gap: 15px;
    }
    .btn_field{
        width: 45%;
    }
}
@media all and (max-width: 768px){
    .profile_personals{
        width: 80%;
        margin-top: 75px;
    }
    .profile_content_item_components_content{
        width: 80%;
    }
    .profile_personals_item_img{
        width: 150px;
    }
    .profile_content_item_components_content_items{

        gap: 15px;
    }

}
@media all and (max-width: 600px){
    .profile_personals{
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 75px;
    }
    .profile_personals_item{
        width: 100%;
        align-items: center;
    }
    .profile_content_item{
        width: 90%;
    }
    .profile_content_item_components_content{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    #should_be_edited_later{
        align-items: flex-start;
    }
    .profile_content_item_components_content_items{
        width: 90%;
        gap: 5vw;
    }
    .profile_content_item_gray_background{
        width: 100%;
    }
    #username{
        justify-content: center;
    }
    .btn_section{
        width: 45%;
    }

}

.info_box{
    width: 100%;
    flex: 1;
}
.insert_box_confirmation{
    align-items: center;
}
.info_box_green_background{
    background-color: #f61;
}
.flex_item{
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;
}
.insert_box_content{
    width: 75%;
    min-height: 400px;
    display: flex;
    flex-direction: row;
    gap: 0px;
}
.insert_box_content_horizontal{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upper_part{
    width: 100%;
    background-color: #eeeeee;
    height: 350px;
}
.main_part{
    width: 37.5%;
    border: 2px solid #ff6611;
    padding: 25px;
    background-color: rgb(255,255,255, 60%);
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;

}
.main_part_item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;

}
.main_part_item h3{
    text-align: justify;
}
.main_part_item p{
    max-width: max-content;
}
.main_part_item_qr_code_field{
    //padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
}
.main_part_item_qr_code_field h2{
    font: normal normal bold 20px/31px Segoe UI;
    letter-spacing: 0px;
    color: #f61;
}
.main_part_item_qr_icon_field{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.main_part_item_qr_code_field_icon {
    width: 25%;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}
.main_part_item_qr_code_field_qr_code{
    width: 50%;
}
.main_part_item_gray_background{
    width: 100%;
    background-color: rgb(255,255,255);
    padding: 15px;
    display: table;
    gap: 15px;
}
.main_part_item_gray_background_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.main_part_item_gray_background_row_item{
    display: flex;
    flex-direction: column;
    flex: 1 1 0px
}
td{
}
.insert_box_content_left{
    flex: .5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    padding: 7px 25px 25px 25px;
}
.insert_box_content_left_item{
    display: flex;
    flex-direction: row;
    gap: 30px;

}
.insert_box_content_left_item_progress_bar_step{
    width: 75px;
    height: 75px;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
}
.insert_box_content_left_item_progress_bar_text{
    display: flex;
    align-items: center;
    justify-content: center;
}
.insert_box_content_left_item_progress_bar_text p{
    font-size: 14px;
    text-align: center;
}
.insert_box_content_right{
    display: flex;
    flex-direction: column;
    border: 2px solid #ff6611;
    padding: 25px;
    flex:.5;
    gap: 25px;
    background-color: rgb(255,255,255, 60%);
}
.section_title{
    font: normal normal bold 30px/39px 'Roboto Condensed', sans-serif;
    letter-spacing: 0px;
    color: #FF6B00;
    text-transform: uppercase;
    opacity: 1;
}
.section_title_2{
    font: normal normal normal 26px/37px Segoe UI;
    letter-spacing: 0px;
    color: #737373;
    text-align: center;
}
.section_text{
    font: normal normal normal 21px/30px Segoe UI;
    letter-spacing: 0px;
    color: #000;
    opacity: 1;
}
.insert_box_content_left_item_progress_bar_step_text{
    font: normal normal bold 30px/39px 'Roboto Condensed', sans-serif;
    letter-spacing: 0px;
    color: #FF6B00;
    text-transform: uppercase;
    opacity: 1;
}

.insert_box_content_left_title{
    font: normal normal bold 45px/65px Segoe UI;
    letter-spacing: 0px;
    color: #f61;
    opacity: 1;
}
.insert_box_content_right_item{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.insert_box_btn_field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btn_field_one_btn{
    width: 30%;
}
.booking_data{
    max-width: 50%;
    flex: .5;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 15px;
    gap: 15px;
    justify-content: space-between;
}
.booking_data_details{
    justify-content: flex-start;
    gap: 28px;/*wegen zeilenabstand*/
}
.booking_data_item{

}
.insert_box_content_right_item_left{
    flex: .1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.insert_box_content_right_item_right{
    flex: .9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.insert_box_input_field{
    width: 100%;
    height: 40px;
    padding: 10px 5px;
    margin: 0px 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #999;
    outline: none;
    background: transparent;
    font-size: 15px;
}
.insert_box_input_field_hidden{
    display: none;
    height: 40px;
    padding: 10px 5px;
    margin: 5px 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border: 1px solid #999;
    border-radius: 10px;
    outline: none;
    background: transparent;
    font-size: 15px;
}
.insert_box_submit_btn_1{
    width: 100%;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin-top: 0px;
    background-color: #f60;
    border: 0;
    outline: none;
    //border-radius: 10px;
    font-size: 14.5pt;
    font-weight: bold;
    color: white;
    margin-top: 18px;
    margin-bottom: 18px;
}
.insert_box_submit_btn{
    color: #fff;
    letter-spacing: 0px;
    font: normal normal bold 17px/24px Segoe UI;
    text-align: center;
    opacity: 1;
    background-color: #f61;
    padding: 15px;
    font-weight: bold;
    display: block;
    border: 0px solid #666;
    box-shadow: 0px 3px 6px #00000029;
    width: 30%;
    height: 45px;

    padding: 10px 20px;
    cursor: pointer;
    display: block;
    outline: none;
    font-weight: bold;
}

.package_dimensions_content_left_item{
    width: 40px;
    height: 40px;
    display: flex;
    background-color: #bd451c;
    justify-content: center;
    align-items: center;
}
.package_dimensions_content_middle_item{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    padding-left: 30px;
    align-items: center;
    background-color: #fff;
}
.package_dimensions_content_right_item{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.insert_box_content_right_section{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    gap: 15px;
}
.insert_box_content_right_section_item{
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
}
.period_content_left_item{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    background-color: #eee;
    gap: 20px;
}
.period_content_right_item{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bd451c;
}
.period_content_time_part{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.period_content_time_up{
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 0 30px 15px 30px;
    border-color: transparent transparent #b9b9b9 transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.period_content_time_number{
    width: 60px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
}
.period_content_time_down{
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(180deg);
    border-style: solid;
    border-width: 0 30px 15px 30px;
    border-color: transparent transparent #b9b9b9 transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.size_h2{
    font-family: 'Source Sans Pro';
    margin-bottom: 0px;
    font-size: 22px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
}

.tool_tip{
    padding: 5px;
    background: rgb(160, 160, 160);
    position: relative;
    border-radius: 5px;
}
.tootip_content{
    max-width: 300px;
}
.tootip_content ul{
    padding-left: 15px;
}
.tootip_content ol{
    padding-left: 15px;
}

.create_qr_code_btn{
    color: #fff;
    letter-spacing: 0px;
    font: normal normal bold 17px/24px Segoe UI;
    text-align: center;
    opacity: 1;
    background-color: #f61;
    padding: 15px;
    font-weight: bold;
    display: block;
    border: 0px solid #666;
    box-shadow: 0px 3px 6px #00000029;
    width: 30%;
    height: 45px;

    padding: 10px 20px;
    cursor: pointer;
    display: block;
    outline: none;
    font-weight: bold;
    margin-right: 15px;
}
.insert_box_confirmation_backToStartPageBtnField{
    width: calc(((37.5% - 54px) * 30)/100);
    margin-bottom: 30px;
}
.insert_box_confirmation_backToStartPageBtnField button{
    border: solid 2px #A0A0A0;
    color: #A0A0A0;
}
@media all and (max-width: 1800px){

    .insert_box_content{
        width: 80%;
        margin-top: 50px;
        margin-bottom: 30px;
        gap: 15px;
    }
    .insert_box_content_left_item_progress_bar_step{
        width: 50px;
        height: 50px;
        background-color: #FFFFFF;
    }
    .insert_box_content_left{
        padding-left: 0px;
        padding-right: 0px;
        margin-top: min(21px, 10%);
    }
    .insert_box_content_right{
        padding: 25px;
        margin-top: min(21px, 10%);
    }
    .insert_box_content_left_item{
        gap: 15px;

    }
    .package_dimensions_content_left_item, .package_dimensions_content_right_item{
        flex: .15;
        height: 50px;/*TODOs: same like flex .2*/
    }
    .package_dimensions_content_middle_item{
        padding-left: 15px;
        flex: .75;
    }
    .btn_field_one_btn{
        width: 45%;
    }
    .insert_box_content_right_item_booking_data{
        width: 100%;
    }
    .main_part{
        width: 50%;
    }

    .insert_box_confirmation_backToStartPageBtnField{
        width: calc(((80% - 54px) * 45)/100);

    }
}
@media all and (max-width: 1200px){

    .insert_box_content{
        width: 80%;
        margin-top: 50px;
        margin-bottom: 30px;
        gap: 15px;
    }
    .insert_box_content_left_item_progress_bar_step{
        width: 50px;
        height: 50px;
        background-color: #FFFFFF;
    }
    .insert_box_content_left{
        padding-left: 0px;
        padding-right: 0px;
        margin-top: min(40px, 10%);
    }
    .insert_box_content_right{
        padding: 25px;
        margin-top: min(40px, 10%);
    }
    .insert_box_content_left_item{
        gap: 15px;

    }
    .package_dimensions_content_left_item, .package_dimensions_content_right_item{
        flex: .15;
        height: 50px;/*TODOs: same like flex .2*/
    }
    .package_dimensions_content_middle_item{
        padding-left: 15px;
        flex: .75;
    }
    .btn_field_one_btn{
        width: 45%;
    }
    .insert_box_content_right_item_booking_data{
        width: 100%;
    }
    .main_part{
        width: 50%;
    }

    .insert_box_confirmation_backToStartPageBtnField{
        width: calc(((80% - 54px) * 45)/100);

    }
}
@media all and (max-width: 992px){

    .insert_box_content{
        width: 80%;
        margin-top: 50px;
        margin-bottom: 30px;
        gap: 15px;
    }
    .insert_box_content_left_item_progress_bar_step{
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        height: 50px;
        min-height: 50px;
        max-width: 50px;
        background-color: #FFFFFF;
    }
    .insert_box_content_left{
        padding-left: 0px;
        padding-right: 0px;
    }
    .insert_box_content_left_item{
        gap: 15px;

    }
    .package_dimensions_content_left_item, .package_dimensions_content_right_item{
        flex: .15;
        height: 50px;/*TODOs: same like flex .2*/
    }
    .package_dimensions_content_middle_item{
        padding-left: 15px;
        flex: .75;
    }
    .btn_field_one_btn{
        width: 45%;
    }
    .insert_box_content_right_item_booking_data{
        width: 100%;
    }
    .main_part{
        width: 80%;
    }

    .insert_box_confirmation_backToStartPageBtnField{
        width: calc(((80% - 54px) * 45)/100);

    }
}
@media all and (max-width: 768px){
    .insert_box_content{
        width: 80%;
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .insert_box_content_left{
        flex-direction: row;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 25px;
    }
    .insert_box_content_left_item{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: .33;
    }
    .insert_box_content_left_item{
        gap: 15px;

    }
    .insert_box_content_left_item_progress_bar_text h4{
        text-align: center;
    }
    .insert_box_content_left_item_progress_bar_step{
        width: 50px;
        height: 50px;
        background-color: #FFFFFF;
    }
    .main_part{
        width: 80%;
    }
    .package_dimensions_content_left_item, .package_dimensions_content_right_item{
        flex: .15;
        height: 50px;/*TODOs: same like flex .2*/
    }
    .package_dimensions_content_middle_item{
        padding-left: 15px;
        flex: .75;
    }
    .insert_box_confirmation_backToStartPageBtnField{
        width: calc(((80% - 54px) * 45)/100);

    }

}
@media all and (max-width: 600px){
    .main_part{
        width: 90%;
    }
    .insert_box_content_left{
        display: flex;
    }
    .insert_box_content{
        width: 90%;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .insert_box_content_right{
        flex: 1;
        padding: 15px;
    }
    .main_part_item_qr_code_field_qr_code{
        width: 100%;
    }
    .package_dimensions_content_left_item, .package_dimensions_content_right_item{
        flex: .2;
        height: 50px;/*TODOs: same like flex .2*/
    }
    .package_dimensions_content_middle_item{
        padding-left: 15px;
        flex: .6;
    }
    .main_part{
        padding: 15px;
    }
    .main_part_item h3{
        text-align: center;
    }
    .tootip_content{
        max-width: 250px;
    }
    .insert_box_content_right_item_booking_data{
        width: 100%;
    }


    .insert_box_content_left{
        flex-direction: row;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 15px;
        padding-bottom: 0px;
        justify-content: space-between;
        gap: 0px;
    }
    .insert_box_content_left_item{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: .33;
    }
    .insert_box_content_left_item{
        gap: 15px;

    }
    .insert_box_content_left_item_progress_bar_text h4{
        text-align: center;
        font-size: 17px;
    }
    .insert_box_content_left_item_progress_bar_step{
        width: 50px;
        height: 50px;
        background-color: #FFFFFF;
    }
    .insert_box_confirmation_backToStartPageBtnField{
        width: calc(((90% - 34px) * 45)/100);

    }
}
.partner{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: auto;
    text-align:center;
    margin: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: center;
}
.carousel {
    width: 100%;
    height: 400px;
    background-color: black;
    text-align:center;
    //margin: 5px auto;
}
.carousel_no_images {
    width: 100%;
    height: 400px;
    background-color: white;
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel_no_images p{
    color: #666666;
}


.carouselInner {
    height: 100%;
    width: 100%;
    background-position: center;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
}

.carouselInner .left {
    flex: 5%;
    height: 100%;
    background-color: rgb(255, 102, 17, .6);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
}
.carouselInner .left_last {
    flex: 5%;
    height: 100%;
    display: grid;
    place-items: center;
}

.carouselInner .center {
    flex: 80%;
    height: 100%;
    display: grid;
    place-items: center;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    text-align-last: center;
}

.h1_partner {
    font-size: 45px;
}

.p_partner {
    font-size: 25px;
    text-align: center;
}

.h1_partner, .p_partner {
    background-color: rgb(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 9px;
    text-align: center;
}

.carouselInner .right {
    flex: 5%;
    height: 100%;
    background-color: rgb(255, 102, 17, .6);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
}
.carouselInner .right_last {
    flex: 5%;
    height: 100%;
    display: grid;
    place-items: center;
}
.title_partner{
    text-align: center;
    padding-top: 2%;

}
.flexSequence{
    display: flex;
    flex-direction: column;
}
.logo_partner img {
    box-shadow: 0 -2px 50px rgba(57, 157, 137, 0.9);
}
.inner_footer_partner{
    padding-top: 0;
    padding-bottom: 2%;
    width: 60%;
    text-align: center;
    margin:0% auto;
    display: flex;
    flex-direction: row;
}
.inner_footer_partner h1{
    text-shadow: 2px 2px 5px #1bc49b;
}

@media all and (max-width: 600px) {
    .partner{
        width: 100%;
    }
    .carousel{
        width: 100%;
        height: 350px;
    }
    .h1_partner {
        font-size: 25px;
    }

    .p_partner {
        font-size: 15px;
    }
    .inner_footer_partner h1{
        font-size: 25px;
    }
    .inner_footer_partner{
        flex-direction: column;
    }
}
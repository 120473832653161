
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 12px;
}


/*
body {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  background: #efefef;
  display: flex;
  justify-content: center;
}*/

.container {
  position: relative;
  background: #fff;
  margin-top: 50px;
  margin-bottom: 30px;
  border-radius: 10px;
  filter: drop-shadow(0 0 10px
          rgba(0, 0, 0, 0.5));
}

.Links {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.accordion {
  position: relative;
  width: 350px;
  margin-left: 40px;
}

.title {
  margin-top: 20px;
  font-size: 2rem;
  text-align: center;
}

.content-accordion {
  width: 100%;
}

.question-answer {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
}

.question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-img {
  position: relative;
  width: 320px;
}

.title-question {
  margin: 1rem 0rem;
  font: bold normal normal 17px/28px "TradeGothic",sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
}

.question-btn {
  font-size: 1.5rem;
  background: transparent;
  border-color: transparent;
  cursor: pointer;
}

.up-icon {
  display: none;
}

.answer {
  font-size: 1.2rem;
  line-height: 1.8;
  display: none;
}

.show-text .answer {
  display: block;
}
.show-text{
  padding-left: 0px;
}
.show-text ul{
    padding-left: 25px;
}
.show-text ol{
    padding-left: 15px;
}
.show-text p{
  margin-bottom: 0.5rem;
  font: normal normal normal 17px/28px TradeGothic,sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
    text-rendering: auto;
}
.show-text .up-icon {
  display: inline;
}

.show-text .down-icon {
  display: none;
}

.up-icon{
    color: #FFFFFF;
}
.down-icon{
    color: #FFFFFF;
}
.load_faq_btn{
    color: #BD451C;
    letter-spacing: 0px;
    font: normal normal bold 17px/21px TradeGothic,sans-serif;
    text-align: center;
    opacity: 1;
    background-color: #FFFFFF;
    font-weight: bold;
    display: block;
    //margin: 0px auto;
    border: 0px solid #EEEEEE;
    box-shadow: 0px 3px 6px #00000029;
    width: max-content;
    height: 30px;
    padding: 5px 30px;
    cursor: pointer;
    display: block;
    margin: 0px auto;
    border: 0;
    outline: none;
    font-weight: bold;
}

.load_faq_btn:hover {
    background-color: #A0A0A0;
    color: #FFFFFF;
}
/*
@media screen and (min-width: 992px) {
  .accordion {
    width: 920px;
    height: 0;
    display: flex;
  }

  .accordion-img {
    position: relative;
    width: 420px;
    top: 88px;
  }

  .question-answer {
    width: 380px;
  }

  .content-accordion {
    margin-top: 85px;
  }

  .title {
    margin-top: 50px;
    font-size: 3rem;
  }
}

 */


.footer{
    bottom: 0;
    left: 0;
    right: 0;
    /*background: linear-gradient(to top, black, #001e67);*/
    background-color: #737373;
    height: auto;
    width: 100%;
    font-family: "Open Sans";
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    /*margin-top: auto;*/
}
.footerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.footerContent h3{
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 3rem;
}
.footerContent p{
    max-width: 500px;
    margin: 10px auto;
    line-height: 3px;
    font-size: 14px;
}
.footerBottom p{
    /*border-top: 1px solid #01aacd;*/
    /*border-top: 1px solid #ffffff;*/
    width: 100%;
    padding: 5px 0;
    text-align: center;
}
.footerBottom p{
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
}
.footer_logo{
    padding-bottom: 5px;
    //box-shadow: 0 -2px 16px rgba(1, 170, 205, 0.39);
    //box-shadow: 0 -2px 18px rgba(255, 255, 255, 0.7);
    border-radius: 5px;
}
.footerBottom a{
    color: white;
    text-decoration: none;
}
.footer_text{
    color: #ffffff;
    font-family: TradeGothic,sans-serif;
    font-weight: normal;
    font-size: 17px;
    opacity: 1;
}
@media all and (max-width: 600px) {
    .footer_text{
        font-size: 14px;
    }
    .footerBottom{
        width: 90%;
        text-align: center;
    }
}
*{
    --nav_height: 10px;
    --slogan_height: 10px;
    --foot_height: 10px;
}
.internal_footer{
    width: 100%;
    height: 75px;
    min-height: 75px;
    background-color: #737373;

}
.chat_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.chat_content_slogan{
    min-height: 20vh;
    max-height: 20vh;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 25px;
    width: 75%;
}
.chat_content_slogan div{
    display: flex;
    align-items: flex-end;
}

.chat_content_slogan img{
    width: 100px;
}
.chat_content_slogan h1{
    color: #FF6B00;
    margin-bottom: -10px;
}
.chat_content_area{
    height: calc(80vh - 150px);
    max-height: calc(80vh - 150px);
    background-color: #eee;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.chat_content_area_window{
    width: 75%;
    display: flex;
    flex-direction: row;
}
.chat_content_area_window_chatroomsShortCut{
    min-width: calc(20% - 48px);
    max-width: calc(20% - 48px);
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

}
.chat_content_area_window_chatroomsShortCut div{
    height: 100%;
    min-width: 48px;
    max-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #A0A0A0;
}
.chat_content_area_window_chatroomsShortCut img{
    width: 24px;
}
.chat_content_area_window_chatroomsOverview{
    height: 100%;
    min-width: 40vw;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin-right: -40%;
    z-index: 2;
}
.chat_content_area_window_chatroomsOverview_closeIcon{
    min-width: 48px;
    max-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #A0A0A0;
}
.chat_content_area_window_chatroomsOverview_closeIcon img{
    width: 24px;
}
.chat_content_area_window_chatroomsOverview_chatrooms{
    flex: 1;
    height: calc(80vh - 150px);
    max-height: calc(80vh - 150px);
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    direction: rtl;
}
.chat_content_area_window_chatrooms{
    height: calc(80vh - 150px);
    max-height: calc(80vh - 150px);
    flex: .25;

    padding-bottom: 25px;
}
.slide_bar{
    display: flex;
    flex-direction: row;
}
.nav_part{
    max-width: 48px;
    background-color: #FFFFFF;
    border-right: #666666 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat_rooms_part{
    z-index: 1;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    direction: rtl;
}
.chat_content_area_window_chatrooms_switchIcon{
    display: none;
}
.chat_content_area_window_chatrooms_content{
    height: calc(80vh - 235px);
    max-height: calc(80vh - 235px);
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    direction: rtl;
}
.no_chatrooms{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.no_chatrooms img{
    width: 50%;
}
.no_chatrooms div{
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

::-webkit-scrollbar {
  width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chat_content_area_window_chatrooms_title{
    border-bottom: 2px solid #A0A0A0;
    max-height: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 5px;
}
.chat_content_area_window_chatrooms_item{
    border-bottom: 2px solid #A0A0A0;
    max-height: 100px;
    min-height: 100px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    gap: 15px;
}
.chat_content_area_window_chatrooms_item:hover{
    background-color: #eee;
}
.chat_content_area_window_chatrooms_item_icon{
    width: 40px;
    height: 40px;
    background-color: rgb(160, 160, 160);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat_content_area_window_chatrooms_item_icon_withBorder{
    width: 40px;
    height: 40px;
    background-color: rgb(255, 102, 17);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat_content_area_window_chatrooms_item_texts{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.chat_content_area_window_chatrooms_item_texts p{

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.chat_content_area_window_chatrooms_item_texts h4{
    text-align: left;
}
.chat_content_area_window_textingfield{
    height: calc(80vh - 150px);
    max-height: calc(80vh - 150px);
    flex: .75;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 60px 25px 25px 25px;
    gap: 25px;
}
.chat_content_area_window_textingfield_middle{
    height: calc(80vh - 150px);
    max-height: calc(80vh - 150px);
    flex: .7;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 25px 25px 25px;
    gap: 10px;
}
.chat_content_area_window_chatrooms_hidden{
    flex: .3;
}
.chat_content_area_window_textingfield_empty{
    display: flex;
    flex-direction: column;
    flex: .75;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 25px;
}
.chat_content_area_window_textingfield_empty div{
    display: none;
}
.chat_content_area_window_textingfield_empty p{
    color: #666666;
    padding-bottom: 15px;
}
.chat_content_area_window_textingfield_otheruserfield{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.chat_content_area_window_textingfield_otheruserfield_back_icon{
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
}
.chat_content_area_window_textingfield_otheruserfield_back_icon img{
    width: 24px;
    transform: rotate(90deg);
}
.chat_content_area_window_textingfield_otheruserfield_texts{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.chat_content_area_window_textingfield_otheruserfield_btn{
    height: 100%;
}
.chat_content_area_window_textingfield_otheruserfield_btn img{
    width: 24px;
}
.chat_content_area_window_textingfield_background{
    max-height: 80%;
    flex: 1;
    background-color: rgba(160, 160, 160, 0.5);
    border-radius: 7.5px;
    padding: 10px 25px 0px 25px;
    box-shadow: 10px 10px 15px 0px rgb(115, 115, 115, .5) inset;
    overflow: scroll;
    overflow-x: hidden;
}
.messages{
    max-height: 80%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: scroll;
    overflow-x: hidden;
}
.my_messages{
    width: 100%;
    //max-height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.my_messages_content{
    max-width: 70%;
    border-radius: 7.5px;
}
.my_messages_content_text{
    padding: 5px 10px 10px 10px;
    border-radius: 7.5px;
    background-color: #FFA868;
}
.my_messages_content_text p{
    word-wrap: break-word;
}
.your_messages{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.your_messages_content{
    max-width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}
.your_messages_content_icon{
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    background-color: #666666;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.your_messages_content div{
    max-width: calc(100% - 55px);
}
.your_messages_content_text{
    min-width: 100%;
    padding: 5px 10px 10px 10px;
    border-radius: 7.5px;
    background-color: #ffffff;
}
.your_messages_content_text p{
    max-width: 100%;
    word-wrap: break-word;
}
.chat_content_area_window_textingfield_inputfield{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}
.chat_content_area_window_textingfield_inputfield_input{
    flex: .85;
}
.chat_content_area_window_textingfield_inputfield_input input{
    width: 100%;
    height: 45px;
    padding: 10px 5px;
    border: 1px solid #999;
    outline: none;
    background-color: #FFFFFF;
    font-family: TradeGothic,sans-serif;
    //font-family: "TradeGothicNextLTW05-Rg2";
    font-size: 17px;
    color: #A0A0A0;
}
.chat_content_area_window_textingfield_inputfield_submitbtn{
    flex: .15;
}


@media all and (max-width: 1200px){/*
    .chat_content_area_window{
        flex: .8;
    }
    .chat_content_slogan{
        width: 80%;
    }
    .chat_content_area_window_chatrooms{
        flex: .35;
    }
    .chat_content_area_window_textingfield{
        flex: .65;
    }
    .chat_content_slogan img{
        width: 100px;
    }
*/
    .chat_content_area_window_textingfield_middle{
    height: calc(80vh - 150px);
    max-height: calc(80vh - 150px);
    flex: .65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 25px 25px 25px;
    gap: 10px;
}
    .chat_content_area_window_chatrooms_hidden{
        flex: .35;
    }
    .chat_content_area_window{
        width: 80%;
    }
}
/*
@media all and (max-width: 992px){
    .chat_content_area{
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .chat_content_area_window{
        width: 80%;
        min-width: 80%;
        max-width: 80%;
        align-items: center;
        justify-content: center;
    }
    .chat_content_area_window_chatrooms{
        display: none;
    }
    .chat_content_area_window_textingfield{
        padding: 60px 25px 25px 25px;
        flex: 1;
    }
    .chat_content_slogan img{
        width: 100px;
    }
}

 */

@media all and (max-width: 992px){/*
    .chat_content{
    }
    .chat_content_area{
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
    }
    .chat_content_area_window{
        width: 80%;
        justify-content: center;
    }
    .chat_content_area_window_textingfield{
        flex: 1;
    }
    .chat_content_area_window_chatrooms{
        flex: .5;
        min-height: 100%;
    }
    .chat_content_area_window{
        min-height: 100%;
        max-height: 100%;
        padding-top: 35px;
    }
    .chat_content_area_window_chatrooms_content{
        min-height: calc(100% - 40px);
        max-height: calc(100% - 40px);
    }
    .chat_content_area_window_chatrooms_title{
        min-height: 40px;
        max-height: 40px;
    }
    .chat_content_area_window_textingfield{
        padding: 0px 0px 10px 0px;
        gap: 15px;
        height: calc(100vh - 185px);
        max-height: calc(100vh - 185px);
    }
    .chat_content_area_window_textingfield_otheruserfield{
        align-items: flex-start;
    }
    .chat_content_area_window_textingfield_otheruserfield_btn{
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
    .chat_content_area_window_textingfield_otheruserfield_btn img{
        width: 24px;
    }
    .chat_content_area_window_textingfield_otheruserfield_btn button{
        width: 45%;
        margin: 0px;
    }
    .chat_content_area_window_textingfield_otheruserfield_texts{
        width: 100%;
        gap: 5px;
    }
    .chat_content_area_window_textingfield_background{
        width: 110%;
        margin-left: -5%;
        border-radius: 0px;
        box-shadow: 0px 10px 15px 0px rgb(115, 115, 115, .5) inset;
        padding: 25px 5% 25px 5%;
    }
    .your_messages_content{
        max-width: calc(100% - 55px);
        gap: 10px;
    }
    .your_messages_content_text{
        max-width: calc(100% - 55px);
        padding: 7.5px;
    }
    .my_messages div{
        max-width: calc(100% - 95px);
        padding: 7.5px;
        border-radius: 7.5px;
    }*/
}

@media all and (max-width: 992px){
    .chat_content_area{
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
    }

    .chat_content_area_window{
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
    }
    .chat_content_slogan{
        width: 80%;
    }
    .chat_content_area_window{
        width: 80%;
        justify-content: flex-end;
    }
    .chat_content_area_window_textingfield{
        flex: 1;
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
        margin-left: -40%;
    }
    .chat_content_area_window_textingfield_middle{
        flex: 1;
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
        margin-left: 0%;




        display: flex;
        flex-direction: column;
        padding: 60px 25px 25px 25px;
        gap: 25px;
    }

    .chat_content_slogan{
        display: none;
    }
    .chat_content_area_window_chatrooms{
        display: flex;
        min-width: 50%;
        z-index: 1;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
        direction: rtl;
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
        box-shadow: 10px 0 5px -2px rgb(0 0 0 / 10%);
    }
    .chat_content_area_window_chatrooms_hidden{
        display: none;
    }
    .chat_content_area_window_chatrooms_content{
        height: calc(100vh - 235px);
        max-height: calc(100vh - 235px);
    }
    .chat_content_area_window_chatrooms_switchIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 48px;
        max-width: 48px;
        background-color: #eee;
        box-shadow: 10px 0 5px -2px rgb(0 0 0 / 10%);
    }
    .chat_content_area_window_chatrooms_title{
        justify-content: flex-end;
    }
    .chat_content_area_window_textingfield_otheruserfield_btn {
        display: flex;
        flex: 1 1;
        justify-content: flex-end;
    }
    .chat_content_area_window_textingfield_empty{
        flex: 1;
    }
    .chat_content_area_window_textingfield_empty div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .chat_content_area_window_textingfield_empty img{
        width: 30%;
        padding-bottom: 40px;
    }
}
@media all and (max-width: 600px){
    .chat_content{
    }
    .chat_content_area{
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
    }
    .chat_content_slogan{
        margin-top: 75px;
    }
    .chat_content_area_window{
        flex-direction: column;
        width: 90%;
        min-height: 100%;
        max-height: 100%;
        padding-top: 35px;
    }
    .chat_content_area_window_chatrooms{
        min-height: 100%;
        max-height: 100%;
        background-color: #EEEEEE;
        box-shadow: 0px 0 0px -0px rgb(0 0 0 / 10%);
    }
    .chat_content_area_window_chatrooms_content{
        min-height: calc(100% - 40px);
        max-height: calc(100% - 40px);
    }
    .chat_content_area_window_chatrooms_title{
        min-height: 40px;
        max-height: 40px;
        background-color: #EEEEEE;
    }
    .chat_content_area_window_textingfield{
        padding: 0px 0px 10px 0px;
        gap: 15px;
        height: calc(100vh - 185px);
        max-height: calc(100vh - 185px);
    }
    .chat_content_area_window_textingfield_otheruserfield{
        align-items: flex-start;
        width: 110%;
        margin-left: -5%;
    }
    .chat_content_area_window_textingfield_otheruserfield_btn{
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
    .chat_content_area_window_textingfield_otheruserfield_btn img{
        width: 24px;
    }
    .chat_content_area_window_textingfield_otheruserfield_btn button{
        width: 45%;
        margin: 0px;
    }
    .chat_content_area_window_textingfield_otheruserfield_texts{
        width: 100%;
        gap: 5px;
    }
    .chat_content_area_window_textingfield_background{
        width: 110%;
        margin-left: -5%;
        border-radius: 0px;
        box-shadow: 0px 10px 15px 0px rgb(115, 115, 115, .5) inset;
        padding: 25px 5% 0px 5%;
    }
    .your_messages_content{
        max-width: calc(100% - 50px);
        gap: 10px;
    }
    .your_messages_content_text{
        //max-width: calc(100% - 50px);
        max-width: 100%;
        padding: 7.5px;
    }
    .my_messages_content{
        max-width: calc(100% - 50px);
        border-radius: 7.5px;
    }
    .my_messages_content_text{
        max-width: 100%;
    }
    .chat_content_area_window_textingfield_otheruserfield_back_icon{
        display: flex;
    }
    .chat_content_area_window_textingfield_inputfield{
        width: 110%;
        margin: -5%;
        justify-content: space-between;
    }
    .no_chatrooms div{
        display: flex;
    }
    .no_chatrooms img{
        width: 30%;
        padding-bottom: 30px;
    }
}









.market_page_content{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.market_page_content_description{
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 30px;
    gap: 25px;
}
.market_page_content_description_item{

}
.market_page_content_description_item img{
    width: 150px;
}
.market_page_content_posts_section{
    background: url(../../static/market/posts_section_background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    gap: 75px;
}
.market_page_content_posts_section_inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    gap: 15px;
}
.market_page_content_posts_section_inputs_item{
    width: 60%;
}
.market_page_content_posts_section_inputs input{
    width: 100%;
    padding: 13px 19px;
    border: 1px solid #666666;
    background-color: #fff;
    outline: none;
    font-size: 19px;
    color: #A0A0A0;
    opacity: 1;
    text-align: left;
}
.market_dropdown_lists{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.market_dropdown{
    width: max-content;
    height: 40px;
    font-family: "Segoe UI";
    font-size: 17px;

}
.market_page_content_posts_section_components{
    display: flex;
    flex-direction: column;
    width: 75%;
    gap: 30px;
}
.market_page_content_posts_section_components_title{

}
.market_page_content_posts_section_components_posts{
    display: flex;
    flex-direction: row;
    gap: 25px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.market_page_content_posts_section_components_btn{
    width: 100%;
}
.market_page_content_posts_section_components_btn button{
    width: 20%;
}
.market_page_content_posts_section_components_posts_row{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}
.market_page_content_posts_section_components_posts_row_item{
    width: 200px;
    height: 250px;
    padding-bottom: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    flex-shrink: 1;
}
.market_page_content_posts_section_components_posts_row_item_img_body{
    width: calc(100% - 30px);
    min-height: 60%;
    max-height: 60%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    cursor: pointer;
}
.market_page_content_posts_section_components_posts_row_item_img_body img{
    max-height: 100%;
}

.market_page_content_posts_section_components_posts_row_item_text_component{
    width: calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
}
.market_page_content_posts_section_components_posts_row_item_text_component p{
    white-space: nowrap;
    overflow: hidden;
}
.market_page_content_posts_section_components_posts_row_item_text_component_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.market_page_content_posts_section_components_posts_row_item_text_component h2{
    font: normal normal normal 17px/22px Segoe UI;
    letter-spacing: 0px;
    color: #000;
    opacity: 1;
}
.market_page_content_posts_section_components_posts_row_item_text_component img{
    width: 12px;
    height: 12px;
    margin-right: -12.5px;
}
.market_page_content_posts_section_components_posts_row_item_text_component div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.market_page_content_posts_section_components_posts_row_item img{
    width: 100%;
}
.market_page_content_posts_section_components_posts_row_item img:hover{
        cursor: pointer;
    }
.market_page_content_faq{

}
.details_page_content{
    width: 50%;
    margin-top: 100px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.details_page_content_item_white_bg{
    background-color: #FFFFFF;
    gap: 25px;
}
.details_page_content_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 30px;
    min-height: 400px;
    padding: 25px;
}
.details_page_content_item_post_details button{
    width: 30%;
    margin: 0px;
}
.details_page_content_item_post_details_btn_field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.details_page_content_item_post_details{
    width: 100%;
}
.details_page_content_item_post_details_date_district{
    display: flex;
    flex-direction: row;
    gap: 25px;
}
.owner_icon{
    width: 40px;
    height: 40px;
    background-color: rgb(160, 160, 160);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact_details{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 15px;
}
.details_page_content_item_post_details textarea{
    width: 100%;
    height: 200px;
    border: 1px solid #666666;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    font-family: "Segoe UI";
    font-size: 17px;
    color: #000000;
    opacity: 1;
    text-align: left;
    resize: none;
}
.details_page_content_item_post_details_message{

}
.details_page_content_item_post_details_message textarea{
    padding: 5px;
}
.details_page_content_item_post_details_message div{
    width: 75%;
}
@media all and (max-width: 470px){
   .market_page_content_posts_section_components_posts_row_item{
        height: 260px;
    }
    .market_page_content_posts_section_components_posts_row_item_text_component_row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
}
@media all and (max-width: 600px){
    .details_page_content{
        width: 90%;
    }
    .details_page_content_item{
        padding: 15px;
    }
    .details_page_content_item_post_details h4{
        text-align: left;
    }
    .details_page_content_item_post_details_message div{
        width: 100%;
    }
    .details_page_content_item{
        min-height: 0px;
    }
    .carousel{
        height: 250px;
    }
    .details_page_content{
        margin-top: 75px;
        margin-bottom: 30px;
    }
    .details_page_content_item_post_details button{
        width: 45%;
    }

    .market_page_content_description{
        width: 90%;
        margin-top: 75px;
        margin-bottom: 30px;
    }
    .market_page_content_description_item h2{
        text-align: center;
    }
    .market_page_content_posts_section_inputs{
        width: 90%;
    }
    .market_page_content_posts_section_inputs_item {
        width: 100%;
    }
    .market_page_content_posts_section_components{
        width: 90%;
    }

    .market_page_content_posts_section_components_posts_row_item{
        width: 47%;
    }
    .market_page_content_posts_section_components_posts_row_item_img_body{
        cursor: pointer;
    }
    .market_page_content_posts_section_components_posts{
        gap: 5vw;
    }
    .market_page_content_posts_section{
        gap: 50px;
    }
    .market_dropdown_lists{
        flex-direction: column;
        align-items: flex-start;
    }
    .market_dropdown{
        width: 50%;
    }
    .market_page_content_posts_section_components_btn button{
        width: 100%;
    }
}


.register{
    width: 900px;
    height: 600px;
    text-align:center;
    margin: 0% auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.register_text{
    flex: .9;
    border-radius: 10px;
    margin: 7.5% 2%;
    padding: 15px;
    //background-color: #169c7c;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.new_register_group{
    border: 3px solid #169c7c;
    border-radius: 10px;
    margin: 5% 2%;
    padding: 15px;
    flex: .1;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}
.register_text h2{
    color: #169c7c;
    font-size: 18px;
    //margin-block-end: 0.41em;
    //flex-shrink: 1;
}
.register_text_line{
    display: flex;
    width: 100%;
    flex-direction: row;
}
.register_text_line_icon{
    flex: .20;
    display: block;

    margin-block-start: .10em;
    margin-block-end: .10em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: flex-start;
}
.register_text_line_icon img{
    width: 45px;
    height: 45px;

    display: block;
    margin-block-start: .10em;
    margin-block-end: .10em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: flex-start;
}
.register_text_line_text{
    flex: .80;
    display: block;
    margin-block-start: .10em;
    margin-block-end: .10em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: flex-start;
}

#location_icon{
    width: 48px;
    margin: 0;
    flex-shrink: 1;
}
.register_group{
    border: 3px solid #169c7c;
    border-radius: 10px;
    margin: 7.5% auto;
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.new_input-field{
    width: 100%;
    height: 40px;
    padding: 10px 5px;
    margin: 0px 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    outline: none;
    background: transparent;
    font-size: 15px;
}
.span_down{
    flex-direction: column;
    flex: 1;
}
.span_down_input{
    width: 40%;
}
.new_submit-btn{
    width: 30%;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin-top: 0px;
    background-color: #f60;
    border: 0;
    outline: none;
    //border-radius: 10px;
    font-size: 14.5pt;
    font-weight: bold;
    color: white;
}
.new_register-btn{
    width: 80%;
    height: 40px;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin: 15px auto;
    background: linear-gradient(to right, #61dafb, #001e67);
    border: 0;
    outline: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    color: white;
}
.line{
    align-items: center;
    border-bottom: 1px solid #dadde1;
    display: flex;
    margin: 20px 16px;
    text-align: center;
}
span_text{
    color: #1bc49b;
    font-size: 12px;
    position: relative;
    text-decoration: none;
    cursor: pointer;
}
.redirectText{
    font-size: 12px;
}

span_text:hover {
    color: #169c7c;
}

span_text::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #1bc49b;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

span_text:hover::before {
    transform: scaleX(1);
}
.check_box_text{
    text-align: left;
    margin-bottom: 5px;
}
.register_btn_field{
    flex-direction: row;
    justify-content: space-between;

}
.register_nextBack_btn{
    color: #fff;
    letter-spacing: 0px;
    font: normal normal bold 17px/24px Segoe UI;
    text-align: center;
    opacity: 1;
    background-color: #f61;
    padding: 15px;
    font-weight: bold;
    display: block;
    border: 0px solid #666;
    box-shadow: 0px 3px 6px #00000029;
    width: 30%;
    height: 45px;

    padding: 10px 20px;
    cursor: pointer;
    display: block;
    outline: none;
    font-weight: bold;
}

@media all and (max-width: 600px){
    #register_checkbox_icon{
        align-items: flex-start;
    }
    /*
    .register{
        width: 100%;
        height: 100%;
        flex-direction: column-reverse;
    }
    .register_text{
        flex: 1;
        width: 90%;
    }
    .new_register_group{
        flex: 1;
        width: 90%;
    }
    .new_register_btn_login{
        font-size: 12px;
    }
    .redirectText{
        font-size: 12px;
    }
    span_login{
        font-size: 12px;
    }
    .login_content_left_item{
        gap: 25px;
    }
    .login_content{
        width: 90%;
    }*/
}
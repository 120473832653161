.dropdown-menu {
  background: #eee;
  min-width: 225px;
  width: max-content;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  padding: 0px;
}

.dropdown-menu li {
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #f61;
}

.dropdown-menu.clicked {
  //display: none;
}

.dropdown-link {
  display: flex;
  width: 100%;
  text-decoration: none;
  color: #666;
  height: 50px;
  padding: 10px 20px 10px 20px;
}
.dropdown-link:hover {
  color: #fff;
}
.dropdown-link img{
  width: 24px;
}

.dropdown-link div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
#dropdown2{
  right: 0px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    //display: none;
  }
  /*
  .dropdown-menu {
    width: max-content;
    position: absolute;
    left: -109%;

    list-style: none;
    text-align: start;
  }

   */
  .dropdown-menu {
    display: flex;
    flex-direction: column;
    width: max-content;
    position: relative;
    list-style: none;
    text-align: start;
    top: 0px;
    padding-left: 34px;
  }/*
  #dropdown1{
    top: 60px;
  }
  #dropdown2{
    top: 180px;
  }*/
  .dropdown-link{
    //height: 60px;
    padding: 10px 20px 10px 20px;
  }
}

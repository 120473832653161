.admin_page_content{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin_page_content_gray_bg{
    background-color: #eee;
}
.admin_page_content_title{
    width: 75%;
}
.admin_page_content_description{
    width: 75%;
    margin-top: 100px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
}
.admin_page_content_description_item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
}
#admin_page_content_description_item_right{
    margin-top: 25px;
}

.admin_page_content_description_item p{
    color: #BD451C;

}
.admin_page_content_description_item h1{
    color: #BD451C;
}
.admin_page_content_description_item img{
    width: 200px;

}
.admin_page_content_item{
    display: flex;
}
.admin_page_content_item_analytics{
    width: 75%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.admin_page_content_item_Datenbank{
    width: 75%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.admin_page_content_item_analytics_item{
    display: flex;
    flex-direction: column;
    padding: 25px;
    flex: .3;
    gap: 5px;
    border-radius: 10px;
    //border: #fff 3px solid;
}
.admin_page_content_item_Datenbank_item{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    padding: 25px;
    flex: .3;
    gap: 5px;
    border-radius: 10px;
    //border: #fff 3px solid;
}
.admin_analytics_page_content_item_Datenbank_item{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
    flex: .3;
    gap: 5px;
    border-radius: 10px;
    //border: #fff 3px solid;
}
.admin_page_content_item_analytics_item h4{
    padding-bottom: 10px;
    padding-top: 20px;
}
.admin_page_content_release_posts_section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
    margin-top: 100px;
    margin-bottom: 30px;
    gap: 30px;
}
.posts{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 25px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.admin_page_content_item_main_field_background{
    flex: 1;
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin_page_content_item_main_field{
    width: 75%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
.admin_page_content_item_main_field_item{
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 6px #00000029;
    opacity: 0.8;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.admin_page_content_item_main_field_item img{
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
}
.admin_page_content_item_main_field_item h3{

    color: #bd451c;
}
.admin_page_inbox_notification{
    color: white;
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 100%;
}
.admin_page_inbox_notification .admin_page_notifications_badge{
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: #BD451C;
    color: white;
    font-size: 15px;
    font-weight: bold;
    margin-top: -13.5px;
    margin-right: -13.5px;
}
.admin_page_content_locker_load{
    width: 75%;
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
}
.admin_page_content_locker_load img{
    width: 125px;
}
.admin_page_content_locker_load h1{
    color: #BD451C;

}
.admin_page_content_item__locker_load_main_field{
    width: 75%;
    padding-top: 75px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.admin_page_content_item__locker_load_main_field_locker_list{
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.admin_page_content_item__locker_load_main_field_locker_field{
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
}
.admin_page_content_item__locker_load_main_field_locker_list_item{
    flex: 1;

}
.admin_page_content_item__locker_load_main_field_locker_list_item_textField{
    width: 100%;
    min-height: 150px;
    background-color: rgb(255, 255, 255, 65%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.admin_page_content_item__locker_load_main_field_locker_list_item_selected{
    background-color: #fff;
}
.admin_page_content_item__locker_load_main_field_locker_list_item_space{
    min-height: 15px;
}
@media all and (max-width: 1200px){
    .admin_page_content_description{
        width: 80%;
        margin-top: 75px;
        gap: 30px;
    }
    .admin_page_content_description_item img{
        width: 150px;
    }
    #admin_page_content_description_item_right{
        margin-top: 0px;
    }
    .admin_page_content_item_main_field{
        width: 80%;
    }
    .admin_page_content_item_main_field_item{
        width: 23%;
    }
    .admin_page_content_locker_load{
        margin-top: 75px;
        margin-bottom: 30px;
        width: 80%;
    }
    .admin_page_content_item__locker_load_main_field{
        width: 80%;
    }
    .admin_page_content_item_analytics{
        width: 80%;
    }
}
@media all and (max-width: 992px){
    .admin_page_content_description{
        width: 80%;
        margin-top: 75px;
        gap: 30px;
    }
    .admin_page_content_description_item img{
        width: 150px;
    }
    #admin_page_content_description_item_right{
        margin-top: 0px;
    }
    .admin_page_content_item_main_field{
        width: 80%;
    }
    .admin_page_content_item_main_field_item{
        width: 23%;
    }
    .admin_page_content_locker_load{
        margin-top: 75px;
        margin-bottom: 30px;
        width: 80%;
    }
    .admin_page_content_item__locker_load_main_field{
        width: 80%;
    }
    .admin_page_content_item_analytics{
        width: 80%;
    }
}
@media all and (max-width: 768px){
    .admin_page_content_description{
        width: 80%;
        margin-top: 75px;
        gap: 30px;
    }
    .admin_page_content_item_main_field{
        width: 80%;
    }
    .admin_page_content_item_main_field_item{
        width: 23%;
    }
    .admin_page_content_locker_load{
        width: 80%;
    }
    .admin_page_content_item__locker_load_main_field{
        width: 80%;
    }
    .admin_page_content_description_item img{
        width: 150px;
    }
    #admin_page_content_description_item_right{
        margin-top: 0px;
    }
    .admin_page_content_item_analytics{
        width: 80%;
    }
}
@media all and (max-width: 600px){
    /*
    .admin_page_content_item{
        width: 90%;
    }
    .admin_page_content_item_main_field_background{
        width: 100%;
    }
    .admin_page_content_locker_load img{
        width: 100px;
    }
    .admin_page_content_locker_load h1{
        font: normal normal bold 30px/47px Segoe UI;
    }
    .admin_page_content_description{
        flex-direction: column;
        gap: 50px;
    }
    .admin_page_content_description_item img{
        width: 150px;
    }
    .admin_page_content_description_item h1{
        font: normal normal bold 35px/52px Segoe UI;
    }
    .admin_page_content_item_main_field{
        width: 90%;
    }
    .admin_page_content_item_main_field_item{
        width: 30%;
    }
    .admin_page_content_item_main_field_item img{
        width: 50px;
        height: 50px;
    }

     */
    .admin_page_content_description{
        width: 90%;
        flex-direction: column;
        margin-top: 75px;
        gap: 30px;
    }
    #admin_page_content_description_item_right{
        margin-top: 0px;
    }
    .admin_page_content_description_item{
        align-items: center;
    }
    .admin_page_content_description_item img{
        width: 150px;
    }
    #adminName{
        text-align: center;
    }
    .admin_page_content_item_main_field{
        width: 90%;
        flex-wrap: wrap;
    }
    .start_site_content_item_main_field_btns{

        flex-wrap: wrap;
    }
    .admin_page_content_item_main_field_item{
        width: 35%;
    }
    .admin_page_content_locker_load{
        width: 90%;
        margin-top: 75px;
        margin-bottom: 30px;
        justify-content: center;
        gap: 25px;
    }
    .admin_page_content_locker_load h1{
        text-align: center;
    }
    .admin_page_content_item__locker_load_main_field{
        width: 90%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .admin_page_content_item_analytics{
        width: 90%;
    }
}
.navbar {
  background: #ff6611/*linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%)*/;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 100000;
}

.navbar-logo {
  border: 3px solid #FFFFFF;
  width: 70px;
  height: 70px;
  margin-bottom: -50px;
  z-index: 100;
  cursor: pointer;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: max-content;
  justify-content: end;

  font-family: TradeGothic, sans-serif;
  font-weight: normal;
  font-size: 21px;
  opacity: 1;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  //height: 80px;
}

.nav-item:hover{
  background-color: #666666;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

/*.nav-links:hover {
  background-color: #f61;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}*/
.nav-links div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.nav-links img{
  width: 24px;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}



@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    width: max-content;
    position: absolute;
    top: 80px;
    right: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;

    color: #666666;
  }
  .nav-item {
    height: 50px;
    padding: 10px 20px 10px 20px;
  }
  .nav-links div{
    align-items: flex-start;
    justify-content: flex-start;
  }
  .nav-menu.active {
    display: flex;
    background: #eee;
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 0px;
    gap: 0px;
    min-width: 280px;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
    color: #666;
  }

  .nav-links:hover {
    background-color: #f61;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    font-size: 40px;
    cursor: pointer;
  }
  .navbar{
    justify-content: flex-end;
  }

  .fa-times {
    color: #fff;
    font-size: 40px;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    //background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-item:hover {
    background: #f61;
    //color: #1888ff;
    transition: 250ms;
  }
  .nav-links:hover{
    color: #fff;
  }
  .extra_tab{
    padding-left: 54px;
  }
}

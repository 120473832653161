.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: max-content;
  min-height: 400px;
  z-index: 3;
  background: #fff;
  padding: 25px;
  display: flex;
  flex-direction: column;

}

.close-button {
  right: 20px;
  top: 15px;
  cursor: pointer;
  font-size: 35px;
  color: #666;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, .7);
}

.modal-content {
  display: flex;
  overflow: auto;
  flex: 1;
}
.modal_group{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  width: 100%;

}
.modal_group_item{
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.modal_group_item_phoneNrInput{
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.modal_group_item_data_left{
  flex: .3;
  display: flex;
  flex-direction: column;
}
.modal_group_item_data_right{
  flex: .7;
  display: flex;
  flex-direction: column;
}
.modal_group_item_btn_section{
  justify-content: flex-end;
}
@media all and (max-width: 992px){
  .modal{
    width: 60%;
    max-height: 400px;
  }
}
@media all and (max-width: 768px){
  .modal{
    width: 80%;
    max-height: 400px;
  }
}
@media all and (max-width: 600px){
  .modal{
    width: 90%;
    max-height: 400px;
  }
  modal_group{
    gap: 15px;
  }
  .modal_group_item{
    flex-direction: column;
  }

}